import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listFilters,
  useUnPinCandidateFilterMutation,
  useChangeFilterOrderMutation,
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
  getFilters,
} from 'store/candidatefilter.slice';
import { BsXLg } from 'react-icons/bs';
import './style.css';
import {
  listCandidates,
  setGroupFilter,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import FAIcon from 'components/lib/FAIcon';
import filterSelector from 'modules/Candidates/components/tables/utils/filterSelector';
import { useFetchCandidatePinnedFilterListQuery } from 'services/candidates/candidate.query';
import useFilter from 'modules/Candidates/components/tables/hooks/useFilter';

//TODO: refactor
interface filterListInt {
  onChange: (e: any) => void;
  active?: any;
  setFilterActive: (e: any) => void;
}

const FilterList = ({
  onChange,
  active = 0,
  setFilterActive,
}: filterListInt) => {
  const dispatch = useDispatch();
  const filter = useFilter({});
  const { data: pinList, isLoading } = useFetchCandidatePinnedFilterListQuery(
    {
      data: filter,
    },
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: data
          ? data?.map((filter) => ({
              ...filter.filter,
              filter: filterSelector(filter.filter as any),
            }))
          : [],
      }),
    }
  );
  const selectedFilter = useSelector(
    (state: any) => state?.candidates?.groupFilter
  );
  const { candidatePgBtn, candidateLoading } = useSelector(
    (state: any) => state.candidates
  );
  const { candidate_filters, candidate_pin_filters } = useSelector(
    (state: any) => state.candidateFilters
  );
  const [activeFilter, setActiveFilter] = useState<number | null>(active);

  const [reqUnpin] = useUnPinCandidateFilterMutation();
  const [reqChangeOrder] = useChangeFilterOrderMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  //using this instead of candidateLoading as indicator, reason : candidateLoading has delays

  const abortFilterControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (active) {
      setActiveFilter(active);

      const param = {
        ...candidatePgBtn,
        filter: active,
      };

      dispatch(listCandidates({ candidatePgBtn: param }));
      localStorage.setItem('candidatesFilter', JSON.stringify(param));
    }
  }, [active]);

  const [filters, setFilters] = useState([]);
  const [draggedFilter, setDraggedFilter] = useState(null);

  const handleDragStart = (index: any) => {
    setDraggedFilter(index);
  };

  const handleDragOver = (index: number) => {
    if (draggedFilter !== null) {
      const newFilters = [...filters];
      const draggedItem = newFilters[draggedFilter];

      newFilters.splice(draggedFilter, 1);
      newFilters.splice(index, 0, draggedItem);
      setFilters(newFilters);
      setDraggedFilter(index);
    }
  };

  const handleDragEnd = async () => {
    const filterOrders: any = [];
    filters.filter(Boolean).map((filter) => {
      filterOrders.push(filter.id);
    });
    const param = {
      filterOrders: filterOrders,
    };
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqChangeOrder({ data: param }).then(async (res: any) => {
      await reqPinList({ signal: abortFilterControllerRef.current.signal });
    });
    setDraggedFilter(null);
  };

  useEffect(() => {
    const candidatedFilterPinData: string | null = null;
    // localStorage.getItem(
    //   'candidatesFilterPin'
    // );

    if (candidatedFilterPinData === null && candidate_pin_filters?.length > 0) {
      let options: any = [];
      // candidate_pin_filters.map((item: any) => {
      //   if (item?.filter?.title === 'All Candidates') {
      //     options.push(item?.filter);
      //     return;
      //   }
      // });
      candidate_pin_filters.map((item: any) => {
        // if (item?.pin) {
        // const item
        if (item?.filter?.title !== 'All Candidates') {
          options.push(item.filter);
        }
        // }
      });

      return setFilters(
        options?.map((data: any) => ({ ...data, filter: filterSelector(data) }))
      );
    } else {
      // setFilters(JSON.parse(candidate_pin_filters));
    }
  }, [candidate_filters, candidate_pin_filters]);

  const handlePin = (id: any) => {
    reqUnpin({ id });

    const updatedFilters = filters.filter((filter) => filter.id !== id);
    // localStorage.setItem('candidatesFilterPin', JSON.stringify(updatedFilters));
    setFilters(updatedFilters);
  };

  const onClick = async (data: any) => {
    const id = data.label === 'All Candidates' ? null : data.value;
    // setIsloading(true);
    onChange(data);

    setActiveFilter(data.value);
    setFilterActive(data.value);
    dispatch(listFilters({ filter: id }));

    let params = {
      // CLEARS FILTER
      page: 1,
      take: 50,
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [] as any,
      skills: [] as any,
      experience: [] as any,
      work_status: null as any,
      job_id: null as any,
      excluded_job_id: null as any,
      status: null as any,
      job_status: null as any,
      resume_updated_on: null as any,
      search: '',
      boolean: true,
      reject_reason: null as any,
      source: null as any,
      sub_source: null as any,
      query: '',
      zip_code: null as any,
      has_resume: false,
      // SAVES NEW FILTER
      filter: id,
    };

    localStorage.setItem('candidatesFilter', JSON.stringify(params));
  };

  useEffect(() => {
    if (!isLoading && pinList.at(0) && !selectedFilter?.value) {
      dispatch(setGroupFilter(pinList?.at(0)?.filter));
    }
  }, [isLoading]);

  return filters?.length > 0 ? (
    <Box
      mt="15px"
      sx={{ fontFamily: 'NunitoSans Regular', color: 'secondarytext' }}
    >
      {pinList.map((item: any, sk: number) => {
        if (item) {
          return (
            <Box
              key={`filterview-${sk}`}
              draggable
              onDragStart={() => handleDragStart(sk)}
              onDragOver={() => handleDragOver(sk)}
              onDragEnd={handleDragEnd}
              className={`filter-button ${
                active !== null && activeFilter === item.id ? 'active' : ''
              }`}
              sx={{
                pointerEvents: candidateLoading ? 'none' : undefined,
                opacity: candidateLoading ? 0.5 : 1,
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                  border: '1px solid rgba(186, 186, 186, 0.4)',
                  borderRadius: '4px',
                  mb: '8px',
                  p: '8px 16px',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
                _hover={{ backgroundColor: 'rgba(239, 229, 255, 0.4)' }}
                onClick={() =>
                  onClick({
                    value: item?.id,
                    label: item?.title,
                    group: item?.group_by_job || false,
                    filter: item?.filter,
                  })
                }
              >
                <Flex gap="5px">
                  <Box color="primary.800" sx={{ mr: '5px' }}>
                    <FAIcon iconName="grip-vertical" />
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '130px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item?.title}
                  </Box>
                  <Box>-</Box>
                  <Box style={{ color: '#6930CA' }}>{item?.data_count}</Box>
                </Flex>
                <Box onClick={() => handlePin(item?.id)}>
                  <BsXLg />
                </Box>
              </Flex>
            </Box>
          );
        } else {
          return <></>;
        }
      })}
    </Box>
  ) : (
    <></>
  );
};

export default FilterList;
