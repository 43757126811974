import React from 'react';
import * as Sentry from '@sentry/react';
import { Button, Stack, Text } from '@chakra-ui/react';

export default function SentryErrorBoundry({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => {
        return (
          <React.Fragment>
            <Stack
              alignItems="center"
              pt={10}
              gap={3}
              w="80%"
              mx="auto"
              height="100%"
              minH={'500px'}
            >
              <Text fontSize="xl" fontWeight="700" textAlign="center">
                Something went wrong
              </Text>
              <Text fontSize="medium" textAlign={'center'}>
                {error.toString()}
              </Text>
              <Button onClick={resetError}>Try Again</Button>
            </Stack>
          </React.Fragment>
        );
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
