import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Td,
  Tr,
  UnorderedList,
  MenuItem,
  Badge,
  useDisclosure,
  Tag,
  VStack,
  Text,
  Stack,
  Portal,
} from '@chakra-ui/react';

import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCandidate,
  listCandidates,
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useGetJobsMutation } from 'store/jobs.slice';
import CandidateJobList from '../../components/CandidateJobList';
import SubmitToClientModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import {
  CandidateData,
  candidateEmail,
  candidateJobs,
  isCheckWithRowType,
} from 'types';
import linkedIn from 'assets/images/linkedin.svg';
import toUrl from 'utils/toUrl';
import EmailsModal from 'components/app/Global/Email/Modal';
import ChangeStatusModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/ChangeStatus';

interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
  candidate?: CandidateData;
}

interface interviewDataInt {
  counter: number;
  id: number;
  job_id: number;
  meeting_date: string;
  meeting_end: string;
  meeting_start: string;
  meeting_timezone: string;
  status: number;
}

interface CandidateTrInterface {
  candidate: CandidateData;
  handleCheck: any;
  isCheck: any;
  handleClick: (candidate: any) => void;
  associateToJob: any;
  addTagClick: any;
  editCandidate: any;
  handleDeleteCandidate: (e: any) => void;
  handleHideCandidate: (e: any) => void;
  HandleMenuItem?: any;
  fromJob?: boolean;
  hasStatus?: boolean;
  isCheckWithJob?: any;
  isCheckWithRow?: isCheckWithRowType[];
  fromGroupByJobs?: boolean;
  key?: any;
  jobTitle?: any;
  clientName?: string;
  jobID?: any;
  clientID?: string;
  statusChangeSuccessCb: (e: any) => void;
  hasInterview?: boolean;
}
const CandidateTR = ({
  candidate,
  handleCheck,
  isCheck,
  handleClick,
  associateToJob,
  addTagClick,
  editCandidate,
  handleDeleteCandidate,
  handleHideCandidate,
  HandleMenuItem,
  fromJob = false,
  hasStatus = false,
  isCheckWithJob = [],
  isCheckWithRow = [],
  fromGroupByJobs = false,
  key,
  jobTitle,
  clientName,
  jobID,
  clientID,
  statusChangeSuccessCb,
  hasInterview = false,
}: CandidateTrInterface) => {
  const params = useParams();

  let emails = candidate?.emails?.filter(
    (email: candidateEmail) => email.primary
  );

  let contacts = candidate?.contact?.filter((contact: any) => contact.primary);

  emails = emails.length === 0 ? candidate?.emails : emails;
  const param = useParams();
  const dispatch = useDispatch();
  const [reqPair] = usePlaceHolderPairMutation();

  const jobsPopulate = (data: any[], id: any) => {
    const memoizedList = (key: number, less?: boolean, color?: boolean) => {
      return (
        <UnorderedList key={`tr-ul-${key}`} m="0">
          {data?.map((job: any, index: number) => {
            return (
              <>
                {(less ? index < key : index > key) && (
                  <ListItem
                    key={`memoized-list-${key}-${index}`}
                    sx={{ listStyle: 'none' }}
                  >
                    <Link
                      href={`/jobs/${job?.job_id}/details`}
                      target="_blank"
                      color={color && '#fff'}
                      fontWeight={400}
                    >
                      {job?.title}
                    </Link>
                  </ListItem>
                )}
              </>
            );
          })}
        </UnorderedList>
      );
    };

    if (data.length > 3) {
      return (
        <>
          {memoizedList(3, true)}
          <Badge
            id={`jobTooltip${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip anchorSelect={`#jobTooltip${id}`} place="bottom" clickable>
            {memoizedList(2, false, true)}
          </Tooltip>
        </>
      );
    }
    return memoizedList(-1);
  };

  const jobStatus = (candidate: any) => {
    try {
      if (candidate?.jobs?.length > 0) {
        const data: any = candidate?.jobs[0];
        return <Text>{data?.sub?.sub_status}</Text>;
      } else {
        return '--';
      }
    } catch (e) {
      return 'Error';
    }
  };

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const openSendEmailClick = async (candidate: any) => {
    const job_id =
      candidateDataJobId ||
      candidate?.jobs?.[0]?.job_id ||
      param?.jobId ||
      null;

    const data = {
      candidate_id: candidate?.id,
      job_id,
      // job_id: fromJob || fromGroupByJobs ? job_id : null,
    } as any;
    await Promise.all([reqPair(data), reqGetCandidate({ id: candidate?.id })]);

    if (candidate?.jobs.length > 0) {
      reqjob({ id: candidate?.jobs?.[0]?.id });
    }
    onOpenSendEmail();
  };

  const [jobList, setJobList] = useState([]);
  const [actionType, steActionType] = useState('');
  const [jobId, setJobId] = useState(null);
  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);
  const [candidateId, setCandidateId] = useState(0);

  const interviewStatus = {
    1: {
      scheme: 'purple',
      color: '#6930CA',
      label: 'Scheduled',
    },
    2: {
      scheme: 'green',
      color: '#38B17A',
      label: 'Completed',
    },
  } as any;

  const { candidateDataJobId, candidateOthers } = useSelector(
    (state: any) => state.candidates
  );

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  // replaced candidateDataJobId with jobID since candidateDataJobId may not correspond
  // the actual job id of the candidate
  const [jobDetails, setJobDetails] = useState<any>(
    // candidateDataJobId
    jobID ? candidate?.jobs.filter((job: any) => job.job_id === jobID)[0] : null
  );
  // const jobDetails: any =
  //   candidateDataJobId &&
  //   candidate?.jobs.filter((job: any) => job.id === candidateDataJobId)[0];

  useEffect(() => {
    if (jobID) {
      // commented this because it continues to update candidateDataJobId.
      // disrupts candidateDataJobId especially if other accordions are opened
      // dispatch(getCandidate({ candidateDataJobId: jobID }))
      const details = candidate?.jobs?.filter(
        (job: any) => job.job_id === Number(jobID)
      )[0];

      setJobDetails(details);
    }
  }, [jobID]);

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
        candidateOthers.length > 0 &&
        candidateOthers[0]?.group_by_job
    );
  }, [candidateOthers]);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  const [reqjob] = useGetJobsMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const statusChangeSuccess = async (e: any) => {
    // setIsBulkAction(false);
    reqCandidates({ data: candidatePgBtn });
    statusChangeSuccessCb(e);
  };

  const onJobSelect = (data: jobselectInt) => {
    if (data.type === 'submit' || data.type === 'interview') {
      reqjob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        if (data.type === 'submit') {
          onOpenSubmit();
        }
      });
    } else if (data.type === 'status') {
      candidate?.jobs.map((job: candidateJobs) => {
        if (Number(job?.job_id) == data.id) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const changeStatus = async (candidate: any) => {
    steActionType('status');
    dispatch(getCandidate({ candidateData: candidate }));
    const jobs_length = candidate?.jobs?.length;
    if (isGroupByJob) {
      onJobSelect({
        id: Number(jobID),
        type: 'status',
        assoc_id: jobDetails?.assoc_id,
      });
    } else {
      if (jobs_length > 0) {
        let jobs = [] as any;
        let job_id = null as any;
        let assoc = null as any;
        await Promise.all(
          candidate?.jobs?.map(async (item: any) => {
            const param = {
              job: {
                ...item,
              },
            };
            jobs.push(param);
            job_id = item.id;
            assoc = item.assoc_id;
          })
        );
        setJobList(jobs);
        if (jobs.length === 1) {
          onJobSelect({
            id: Number(job_id),
            type: 'status',
            assoc_id: assoc,
          });
        } else {
          onOpenJobList();
        }
      }
    }
  };

  const submitToClient = async (candidateData: any) => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('submit');
    if (isGroupByJob) {
      onJobSelect({
        id:
          jobID ||
          Number(jobDetails?.job?.job_id) ||
          candidateData?.jobs?.[0]?.job_id ||
          candidateDataJobId,
        type: 'submit',
        assoc_id: jobDetails?.assoc_id || candidateData?.jobs?.[0]?.assoc_id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobId) {
            if (
              (item.candidate_status_id === 2 || item?.status?.id === 2) &&
              item?.job_id == param?.jobId
            ) {
              // if (item?.job) {
              jobs.push(item);
              // job_id = item?.job?.id;
              job_id = item?.job_id;
              // assoc = item;
              assoc = item?.assoc_Id;
              // }
            }
          } else {
            if (item.candidate_status_id === 2 || item?.status?.id === 2) {
              // if (item?.job) {
              jobs.push(item);
              // job_id = item?.job?.id;
              job_id = item?.job_id;
              // assoc = item;
              assoc = item?.assoc_Id;
              // }
            }
          }
        })
      );
      setJobList(jobs);
      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'submit',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const onEmailClick = async (candidate: any) => {
    const data = {
      candidate_id: candidate?.id,
      job_id: candidate?.job_id || param?.jobId || null,
    } as any;
    setJobId(data.job_id);
    setCandidateId(data.candidate_id);
    reqPair(data);
    onOpenEmail();
    if (candidate?.jobs?.length > 0) {
      reqjob({ id: candidate?.jobs?.[0]?.job_id });
    }
  };

  const currentDate = moment();

  const TRContent = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box fontSize="14px" textAlign="center" color="default.primarytext">
          {jobStatus(candidate)}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      {candidate.jobs?.length > 0 ? (
        <Td>
          <Flex
            fontSize="14px"
            color="default.primarytext"
            whiteSpace="normal"
            width="200px"
            gap="10px"
            alignItems="flex-end"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
          >
            {candidate.jobs?.length > 0 &&
              jobsPopulate(candidate.jobs, candidate.id)}
          </Flex>
        </Td>
      ) : (
        <Td onClick={() => handleClick(candidate)}></Td>
      )}
    </>
  );

  const TRContentBlasted = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      {candidate.jobs?.length > 0 ? (
        <Td>
          <Flex
            fontSize="14px"
            color="default.primarytext"
            whiteSpace="normal"
            width="200px"
            gap="10px"
            alignItems="flex-end"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
          >
            {candidate.jobs?.length > 0 &&
              jobsPopulate(candidate.jobs, candidate.id)}
          </Flex>
        </Td>
      ) : (
        <Td onClick={() => handleClick(candidate)}></Td>
      )}
    </>
  );

  const TRContentScreen = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box fontSize="14px" textAlign="center" color="default.primarytext">
          {jobStatus(candidate)}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
    </>
  );

  const TRContentSubmits = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
        >
          {moment(
            candidate?.jobs?.filter(
              (val: any) => val.job_id === candidate?.job_id
            )[0]?.submitted_on
          ).format('MM/DD/YYYY')}
        </Box>
        <Link>
          {currentDate.diff(
            moment(
              candidate?.jobs?.filter(
                (val: any) => val.job_id === candidate?.job_id
              )[0]?.submitted_on
            ).format('MM/DD/YYYY'),
            'days'
          )}{' '}
          day(s)
        </Link>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
    </>
  );

  const TRContentInterviews = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate?.jobs &&
          interviewStatus[
            candidate?.jobs?.find((item: any) => item.job_id == jobID)
              ?.interview?.status
          ]?.label && (
            <Badge
              colorScheme={
                (candidate?.jobs &&
                  interviewStatus[
                    candidate?.jobs?.find((item: any) => item.job_id == jobID)
                      ?.interview?.status
                  ]?.scheme) ||
                'green'
              }
              sx={{
                textTransform: 'capitalize',
                p: '4px 8px',
                fontSize: '14px',
                borderRadius: '2px',
                color:
                  candidate?.jobs &&
                  interviewStatus[
                    candidate?.jobs.find((item: any) => item.job_id == jobID)
                      .interview?.status
                  ]?.color,
              }}
            >
              {(candidate?.jobs &&
                interviewStatus[
                  candidate?.jobs.find((item: any) => item.job_id == jobID)
                    .interview?.status
                ]?.label) ||
                'Undefined'}
            </Badge>
          )}
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        {candidate?.jobs?.find((item: any) => item.job_id == jobID)?.interview
          ?.counter > 0 && (
          <>
            {hasInterview &&
              candidate?.jobs?.find((item: any) => item.job_id == jobID)
                ?.interview?.counter > 0 && (
                <Box isTruncated mb="3px">
                  <Badge
                    sx={{
                      borderRadius: '2px',
                      p: '4px 8px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#2B2D42',
                      textTransform: 'capitalize',
                    }}
                  >
                    Interview #{' '}
                    {
                      candidate?.jobs?.find((item: any) => item.job_id == jobID)
                        ?.interview?.counter
                    }
                  </Badge>
                </Box>
              )}
            <Link fontSize="14px" isTruncated textAlign="center" mb="3px">
              {moment(
                candidate?.jobs?.find((item: any) => item.job_id == jobID)
                  ?.interview?.meeting_date
              ).format('MM/DD/YYYY')}
            </Link>
            <Box
              fontSize="14px"
              color="default.secondarytext"
              isTruncated
              textAlign="center"
            >
              {moment(
                candidate?.jobs?.find((item: any) => item.job_id == jobID)
                  ?.interview?.meeting_start,
                'HH:mm:ss'
              ).format('h:mm A')}{' '}
              -{' '}
              {moment(
                candidate?.jobs?.find((item: any) => item.job_id == jobID)
                  ?.interview?.meeting_end,
                'HH:mm:ss'
              ).format('h:mm A')}{' '}
              {
                candidate?.jobs?.find((item: any) => item.job_id == jobID)
                  ?.interview?.meeting_timezone
              }
            </Box>
          </>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
    </>
  );

  const TRContentOffers = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        <Box fontSize="14px" color="default.primarytext">
          {moment(
            candidate?.jobs?.filter(
              (val: any) => val.job_id === candidate?.job_id
            )[0]?.offered_date
          ).format('MM/DD/YYYY')}
        </Box>
        <Link>
          {currentDate.diff(
            moment(
              candidate?.jobs?.filter(
                (val: any) => val.job_id === candidate?.job_id
              )[0]?.offered_date
            ).format('MM/DD/YYYY'),
            'days'
          )}{' '}
          day(s)
        </Link>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
    </>
  );

  const TRContentHired = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        <Box fontSize="14px" color="default.primarytext">
          {moment(
            candidate?.jobs?.filter(
              (val: any) => val.job_id === candidate?.job_id
            )[0]?.placement_form?.candidate_start_date
          ).format('MM/DD/YYYY')}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        <Box fontSize="14px" color="default.primarytext">
          {moment(
            candidate?.jobs?.filter(
              (val: any) => val.job_id === candidate?.job_id
            )[0]?.placement_form?.placement_date
          ).format('MM/DD/YYYY')}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)} textAlign="center">
        {candidate?.jobs?.filter(
          (val: any) => val.job_id === candidate?.job_id
        )[0]?.offered_date && (
          <>
            <Box fontSize="14px" color="default.primarytext">
              {moment(
                candidate?.jobs?.filter(
                  (val: any) => val.job_id === candidate?.job_id
                )[0]?.offered_date
              ).format('MM/DD/YYYY')}
            </Box>
            <Link>
              {currentDate.diff(
                moment(
                  candidate?.jobs?.filter(
                    (val: any) => val.job_id === candidate?.job_id
                  )[0]?.offered_date
                ).format('MM/DD/YYYY'),
                'days'
              )}{' '}
              day(s)
            </Link>
          </>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
    </>
  );

  const TRContentRejected = (
    <>
      <Td>
        <Box
          fontSize="14px"
          color="default.primarytext"
          sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
        >
          <VStack spacing={1} align="stretch">
            <Box
              onClick={() => onEmailClick(candidate)}
              sx={{ fontWeight: 700, color: 'primary.800', cursor: 'pointer' }}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {emails?.[0]?.email}
            </Box>
            <Box>
              <Link href={`tel:${contacts[0]?.number}`}>
                {contacts[0]?.number}
              </Link>
            </Box>
          </VStack>
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Stack spacing="5px" alignItems="center">
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'center' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
          <Link>
            {candidate?.location_distance?.distance
              ? `${Math.ceil(
                  parseFloat(String(candidate?.location_distance?.distance))
                )?.toLocaleString()}`
              : 0}{' '}
            miles
          </Link>
        </Stack>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        <Box
          fontSize="14px"
          textTransform="capitalize"
          color="default.primarytext"
          textAlign="center"
        >
          {candidate.work_status?.status}
        </Box>
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.resume_update_on && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      <Td onClick={() => handleClick(candidate)}>
        {candidate.last_activity && (
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
          </Box>
        )}
      </Td>
      {candidate.jobs?.length > 0 ? (
        <Td>
          <Flex
            fontSize="14px"
            color="default.primarytext"
            whiteSpace="normal"
            width="200px"
            gap="10px"
            alignItems="flex-end"
            sx={{ w: '200px', textWrap: 'balance', textAlign: 'left' }}
          >
            {candidate.jobs?.length > 0 &&
              jobsPopulate(candidate.jobs, candidate.id)}
          </Flex>
        </Td>
      ) : (
        <Td onClick={() => handleClick(candidate)}></Td>
      )}
    </>
  );

  const [trContent, setTRContent] = useState(TRContent);

  useEffect(() => {
    switch (params.jobsTabNav) {
      case 'blasted':
        setTRContent(TRContentBlasted);
        break;
      case 'screen':
        setTRContent(TRContentScreen);
        break;
      case 'submits':
        setTRContent(TRContentSubmits);
        break;
      case 'interviews':
        setTRContent(TRContentInterviews);
        break;
      case 'offers':
        setTRContent(TRContentOffers);
        break;
      case 'hired':
        setTRContent(TRContentHired);
        break;
      case 'rejected':
        setTRContent(TRContentRejected);
        break;
      default:
        setTRContent(TRContent);
        break;
    }
  }, [params.jobsTabNav]);

  return (
    <>
      <Tr
        key={`candidate-tr-${key}-${candidate.id}`}
        _hover={{
          bg: '#f8f9fa',
        }}
        cursor="pointer"
      >
        <Td
          position="sticky"
          left={0}
          bg="white"
          boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
          _hover={{
            bg: '#f8f9fa',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={(e: any) => {
                !fromGroupByJobs
                  ? handleCheck(e, candidate?.rowId)
                  : handleCheck(e, candidate?.jobs?.[0]?.job_id);
              }}
              isChecked={
                fromJob
                  ? isCheck.includes(candidate?.id)
                  : !fromGroupByJobs || fromJob
                  ? isCheckWithRow.some(
                      (obj: any) =>
                        obj.id === candidate?.id &&
                        obj.rowId === candidate?.rowId
                    )
                  : isCheckWithJob.some(
                      (obj: any) =>
                        obj.id === candidate?.id &&
                        obj.job_id === candidate?.jobs?.[0]?.job_id
                    )
              }
              id={candidate.id.toString()}
              key={candidate.id}
              colorScheme="purple"
            />
            <Flex alignItems="center" justifyContent="space-between" gap="20px">
              <Flex
                onClick={() => handleClick(candidate)}
                flexDirection="column"
                justifyContent="center"
                width="300px"
                minH="60px"
              >
                <Box
                  fontWeight="bold"
                  fontSize="14px"
                  color="default.primarytext"
                  isTruncated
                  textTransform="capitalize"
                >
                  <Flex gap={2}>
                    {[candidate.first_name, candidate.last_name].join(' ')}{' '}
                    {candidate?.linkedin_url != '' &&
                      candidate?.linkedin_url != null && (
                        <Link
                          target="_blank"
                          href={toUrl(candidate?.linkedin_url)}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={linkedIn}
                            alt="LinkedIn Logo"
                            style={{
                              width: '15px',
                              height: '15px',
                              objectFit: 'contain',
                            }}
                          />
                        </Link>
                      )}
                  </Flex>
                </Box>
                {candidate.latest_job_title && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_job_title}
                  </Box>
                )}
                {candidate.latest_employer && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_employer}
                  </Box>
                )}
              </Flex>
              <Menu>
                <MenuButton>
                  <Button
                    htmlType="menu"
                    variant="ghost"
                    iconName="ellipsis-h"
                    iconSize="xl"
                  />
                </MenuButton>
                <Portal>
                  {!fromJob ? (
                    <MenuList fontSize="sm">
                      <MenuItem>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="comment-dots" />
                          <Box ml={12} cursor="pointer">
                            Send SMS
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem onClick={() => openSendEmailClick(candidate)}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="envelope" />
                          <Box ml={12} cursor="pointer">
                            Send Email
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem onClick={() => associateToJob(candidate)}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="eye" />
                          <Box ml={12} cursor="pointer">
                            Associate to Job
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          onClick={async () => {
                            reqGetCandidate({ id: candidate.id });
                            submitToClient(candidate);
                          }}
                        >
                          <FAIcon iconName="clipboard" />
                          <Box ml={12} cursor="pointer">
                            Submit to Client
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem onClick={() => addTagClick(candidate.id)}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="tags" />
                          <Box ml={12} cursor="pointer">
                            Add Tags
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => changeStatus(candidate)}
                        isDisabled={candidate?.jobs?.length === 0}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="person-circle-check" />
                          <Box ml={12} cursor="pointer">
                            Change Status
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem onClick={() => editCandidate(candidate)}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="pencil" />
                          <Box ml={12} cursor="pointer">
                            Edit
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem onClick={() => handleHideCandidate(candidate)}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="eye-slash" />
                          <Box ml={12} cursor="pointer">
                            Hide
                          </Box>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDeleteCandidate(candidate)}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FAIcon iconName="trash" />
                          <Box ml={12} cursor="pointer">
                            Delete
                          </Box>
                        </Flex>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList fontSize="sm">{HandleMenuItem}</MenuList>
                  )}
                </Portal>
              </Menu>
            </Flex>
          </Flex>
        </Td>

        {trContent}
      </Tr>

      {isOpenSendEmail && (
        <EmailsModal
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          id={candidate.id}
          email={emails?.[0]?.email || ''}
          candidate_id={candidateId}
          job_id={jobId ? jobId : undefined}
          allowedCategory={['Candidates', 'Jobs', 'Signatures']}
        />
      )}

      {isOpenJobList && (
        <CandidateJobList
          jobs={jobList}
          isOpen={isOpenJobList}
          onClose={onCloseJobList}
          onSelect={(e: any) => onJobSelect(e)}
          type={actionType}
        />
      )}

      {isOpenSubmit && (
        <SubmitToClientModal
          isOpen={isOpenSubmit}
          onClose={onCloseSubmit}
          candidate={candidate}
          job_id={jobId}
          callback={async () => {
            statusChangeSuccessCb(candidate);
          }}
          jobTitle={jobTitle}
          clientName={clientName}
          jobID={jobID}
          clientID={clientID}
        />
      )}

      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={(e: any) => statusChangeSuccess(e)}
          candidate={candidate}
        />
      )}
      {isOpenEmail && (
        <EmailsModal
          isOpen={isOpenEmail}
          onClose={onCloseEmail}
          id={candidateId}
          email={emails?.[0]?.email || ''}
          candidate_id={candidateId}
          job_id={jobId ? jobId : undefined}
          allowedCategory={['Candidates', 'Jobs', 'Signatures']}
        />
      )}
    </>
  );
};

export default CandidateTR;
