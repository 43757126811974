// React and Redux imports
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

// Importing mutations from slices
import {
  useListCandidatesMutation,
  listCandidates,
} from 'store/candidates.slice';
import { useListJobsMutation, useGetJobsMutation } from 'store/jobs.slice';

export const useViewCandidatesModuleServices = () => {
  // Redux hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Set the document direction
  document.documentElement.dir = 'ltr';

  // API mutations
  const [reqJobs] = useListJobsMutation();
  const [reqCandidates] = useListCandidatesMutation();

  const [reqJobView] = useGetJobsMutation();

  // Redux selectors
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const { jobData, jobPgBtn } = useSelector((state: any) => state.jobs);

  // Local state
  const [hasStatus, setHasStatus] = useState(true);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));
  const [activeTab, steActiveTab] = useState(params?.jobsTabId || '');
  const [reloadCount, setReloadCount] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isClear, setIsClear] = useState(false);

  // Effect to handle tab status based on route params
  useEffect(() => {
    if (params?.jobsTabId === 'search') {
      setHasStatus(false);
    } else {
      setHasStatus(true);
    }
  }, [params?.jobsTabId]);

  // Effect to fetch job details based on jobId param
  useEffect(() => {
    const fetch = async () => {
      await reqJobView({ id: params?.jobId });
    };
    fetch();
  }, [params?.jobId]);

  // Handle route navigation
  const handleRoute = useCallback(
    (route: string) => {
      navigate(`/jobs/${jobData?.id}/view-candidates/${route}`);
    },
    [jobData?.id, navigate]
  );

  // Handle back button click
  const handleBackButton = useCallback(async () => {
    await reqJobs({ data: jobPgBtn });
    navigate(`/jobs`);
  }, [navigate]);

  // Effect to update active tab and fetch candidates list
  useEffect(() => {
    steActiveTab(params.jobsTabId);
    let payload = {
      ...candidatePgBtn,
    };

    if (params.jobsTabId == 'search') {
      payload = {
        ...candidatePgBtn,
        excluded_job_id: null,
        filter: null,
        job_id: null,
        job_status: null,
        status: null,
        zip_code: null,
      };
    }

    if (params.jobsTabId == 'in-progress') {
      payload = {
        ...candidatePgBtn,
        excluded_job_id: null,
        job_id: params?.jobId,
        job_status: [3],
        status: [2],
        query: '',
        search: '',
        zip_code: null,
        filter: null,
      };
    }

    dispatch(
      listCandidates({
        candidatePgBtn: payload,
      })
    );
  }, [params.jobsTabId]);

  // Parse location data
  const locationParse = (location: any) => {
    try {
      if (jobData?.job) {
        const loc = [
          jobData.job.location?.city,
          jobData.job.location?.state,
        ].join(', ');
        if (loc !== ', ') {
          return loc;
        }
      } else if (location) {
        return [location?.city, location?.state].join(', ');
      }
      return '';
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  // Memoized candidate payload
  const candidatePayload = useMemo(() => {
    return { ...candidatePgBtn };
  }, [candidatePgBtn]);

  // Fetch and store filter data from localStorage
  const candidatedFilterData: string | null =
    localStorage.getItem('candidatesFilter');
  const filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  let initialValue = {
    search: filter?.query || '',
  };

  // Effect to handle filter data and candidate fetching
  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    localStorage.setItem('candidatesFilter', JSON.stringify(filter));
    reqCandidates({ data: filter, signal: abortControllerRef.current.signal });
  }, [candidatePayload]);

  // Effect to update filter cache and refetch candidates on filter change
  useEffect(() => {
    if (
      filterCache !== '{}' &&
      filterCache !== JSON.stringify(candidatePayload)
    ) {
      const param = {
        ...candidatePayload,
        job_id: params.jobsTabId === 'search' ? null : Number(params.jobId),
        filter: null,
        excluded_job_id:
          params.jobsTabId === 'search' ? Number(params.jobId) : null,
      };

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      localStorage.setItem('candidatesFilter', JSON.stringify(param));
      reqCandidates({ data: param, signal: abortControllerRef.current.signal });
    }

    setFilterCache(JSON.stringify(candidatePayload));
  }, [candidatePayload, params?.jobId]);

  return {
    isClear,
    handleBackButton,
    jobData,
    locationParse,
    activeTab,
    candidatePgBtn,
    params,
    reloadCount,
    setIsClear,
    initialValue,
    hasStatus,
    setReloadCount,
  };
};
