import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import Select from 'components/ui/Select/Select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useFetchSearchClientQuery } from 'services/client/client.query';
import {
  useFetchCountriesQuery,
  useFetchJobSpecificationsQuery,
} from 'services/common/constants.query';
import { addressFormat } from 'utils/utilFormat';
import { InitialValueType } from '../type';

export default function CompanyDetails() {
  const formikContext = useFormikContext<InitialValueType>();
  const {
    data: jobSpecList,
    isLoading: isJobSpecListLoading,
    isSuccess: isJobSpecListSuccess,
  } = useFetchJobSpecificationsQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((job: any) => ({
              label: job.specification,
              value: job.id,
            }))
          : [],
        ...other,
      }),
    }
  );
  const { data: countryList } = useFetchCountriesQuery({});

  const [companySearchQuery, setCompanySearchQuery] = useState('');
  const { data: companyList, isFetching: isCompanyListLoading } =
    useFetchSearchClientQuery(
      { search: companySearchQuery },
      {
        skip: !isJobSpecListSuccess,
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data.map((data: any) => ({
                value: data.id,
                label: data.name,
                website: data.website,
                address: addressFormat(data.address, countryList),
                specification: data.spec_id,
              }))
            : [],
          ...other,
        }),
      }
    );

  const jobSpecificationValue = useMemo(() => {
    return (
      jobSpecList.find(
        (job) => parseInt(job.value) === formikContext?.values?.specification_id
      ) || { label: '', value: '' }
    );
  }, [jobSpecList, formikContext.values.specification_id]);

  const handleSearch = debounce((search: string) => {
    setCompanySearchQuery(search);
  }, 400);

  return (
    <>
      <Text fontWeight={600} mb="34px">
        Company Details
      </Text>
      <Flex gap="32px" mb="34px">
        <Field name="title">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.title && touched.title)}
              isRequired
            >
              <FormLabel>Title</FormLabel>
              <Input
                id="title"
                name="title"
                type="text"
                placeholder="Title"
                variant="outline"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.title)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="company">
          {({
            form: { errors, touched, values, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.company && touched.company)}
              isRequired
            >
              <FormLabel>Company</FormLabel>
              <AtsCraetableSelDyn
                id="company"
                name="company"
                defaultValue={values.company}
                placeholder="Company"
                onChange={(event: any) => {
                  setFieldValue('company', event);
                  if (event?.address) {
                    setFieldValue('address', event.address);
                  }

                  if (event?.website) {
                    setFieldValue('website', event.website);
                  }

                  if (event?.specification) {
                    setFieldValue('specification_id', event.specification);
                  }

                  if (!event) {
                    setFieldValue('address', []);
                    setFieldValue('website', '');
                  }
                }}
                onInputChange={handleSearch}
                isLoading={isCompanyListLoading}
                options={companyList}
                isClearable
              />
              <FormErrorMessage>{String(errors.company)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Field name="specification_id">
          {({
            form: { errors, touched, values, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(
                !!errors.specification_id && touched.specification_id
              )}
              isRequired
            >
              <FormLabel>Industry</FormLabel>

              <Field
                name="specification_id"
                options={jobSpecList}
                component={Select}
                value={jobSpecificationValue}
                placeholder="Select"
                onChange={(e: any) => {
                  setFieldValue('specification_id', e.value);
                }}
                isDisabled={values.specification_id}
                isLoading={isJobSpecListLoading}
                isError={!!errors.specification_id && touched.specification_id}
              />
              <FormErrorMessage>
                {String(errors.specification_id)}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="website">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.website && touched.website)}
              isRequired
            >
              <FormLabel>Company Website </FormLabel>
              <Input
                id="website"
                name="website"
                type="text"
                placeholder="Company Website"
                variant="outline"
                value={values.website}
                onChange={handleChange}
                disabled={values.website}
              />
              <FormErrorMessage>{String(errors.website)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
