import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
  Text,
} from '@chakra-ui/react';
import AddressComponent from 'components/app/Leads/components/AddressComponent';
import FAIcon from 'components/lib/FAIcon';
import { Field, FieldArray, FieldProps } from 'formik';
import { useFetchCountriesQuery } from 'services/common/constants.query';
import { InitialValueType } from '../type';

interface AddressInformationPropsType {
  allowAddAddress: boolean;
}
export default function AddressInformation({
  allowAddAddress,
}: AddressInformationPropsType) {
  const { data: countryList } = useFetchCountriesQuery({});
  const countryOption =
    countryList?.map((country: any) => ({
      label: country.country,
      value: country.id,
      code: country.code,
    })) ?? [];
  return (
    <>
      <Box mb="34px">
        <Text fontWeight={600} mb="34px">
          Address Information{' '}
          <Box as="span" color="caution.800">
            *
          </Box>
        </Text>
        <Field name="address">
          {({
            form: { errors, touched, values, handleChange, setFieldValue },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.address && touched.address)}
            >
              <FieldArray name="address">
                {({ remove, push }) => (
                  <div>
                    {values.address &&
                      values.address.length > 0 &&
                      values.address.map((job: any, index: number) => (
                        <Accordion
                          defaultIndex={[index]}
                          allowMultiple
                          key={`jobAdd-${index}`}
                        >
                          <AccordionItem>
                            <Flex position="relative">
                              <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                  <Box
                                    as="span"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    fontWeight={600}
                                    color="default.primarytext"
                                    mb="8px"
                                  >
                                    {job.location
                                      ? `${job.location}${
                                          job.primary
                                            ? ` (Primary Address)`
                                            : ''
                                        }`
                                      : `Address ${index + 1}`}
                                  </Box>
                                </Box>

                                <Box>
                                  <AccordionIcon color="#6930CA" />
                                </Box>
                              </AccordionButton>
                              <Flex
                                gap="15px"
                                alignItems="center"
                                position="absolute"
                                right="55px"
                                top="0"
                                bottom="0"
                              >
                                <Field
                                  as={Switch}
                                  colorScheme="purple"
                                  name={`address.${index}.primary`}
                                  isChecked={job.primary}
                                  onChange={() =>
                                    setFieldValue(
                                      'address',
                                      values.address.map(
                                        (address: any, idx: any) => ({
                                          ...address,
                                          primary: idx === index,
                                        })
                                      )
                                    )
                                  }
                                />
                                <Box>
                                  <Button
                                    leftIcon={<FAIcon iconName="close" />}
                                    onClick={() => remove(index)}
                                    size="sm"
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Flex>
                            </Flex>
                            <AccordionPanel pb={4}>
                              <AddressComponent
                                setFieldValue={setFieldValue}
                                index={index}
                                handleChange={handleChange}
                                countryOption={countryOption}
                                countryField={{
                                  name: `address.${index}.country`,
                                  value: job.country,
                                  errors: (errors.address as any[])?.[index]
                                    ?.country,
                                  touched: (touched.address as any[])?.[index]
                                    ?.country,
                                }}
                                locationField={{
                                  name: `address.${index}.location`,
                                  value: job.location,
                                  errors: (errors.address as any[])?.[index]
                                    ?.location,
                                  touched: (touched.address as any[])?.[index]
                                    ?.location,
                                }}
                                addressField={{
                                  name: `address.${index}.address`,
                                  value: job.address,
                                  errors: (errors.address as any[])?.[index]
                                    ?.address,
                                  touched: (touched.address as any[])?.[index]
                                    ?.address,
                                }}
                                cityField={{
                                  name: `address.${index}.city`,
                                  value: job.city,
                                  errors: (errors.address as any[])?.[index]
                                    ?.city,
                                  touched: (touched.address as any[])?.[index]
                                    ?.city,
                                }}
                                stateField={{
                                  name: `address.${index}.state`,
                                  value: job.state,
                                  errors: (errors.address as any[])?.[index]
                                    ?.state,
                                  touched: (touched.address as any[])?.[index]
                                    ?.state,
                                }}
                                zipField={{
                                  name: `address.${index}.zip_code`,
                                  value: job.zip_code,
                                  errors: (errors.address as any[])?.[index]
                                    ?.zip_code,
                                  touched: (touched.address as any[])?.[index]
                                    ?.zip_code,
                                }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ))}
                    {allowAddAddress && (
                      <Box mt="50px">
                        <Button
                          leftIcon={<FAIcon iconName="plus" />}
                          onClick={() => {
                            push({
                              location: '',
                              address: '',
                              city: '',
                              state: '',
                              country: '',
                              zip_code: '',
                              primary: allowAddAddress,
                            });
                          }}
                        >
                          {values.address && values.address.length > 0
                            ? 'Add Another Address'
                            : 'Add Address'}
                        </Button>
                      </Box>
                    )}
                  </div>
                )}
              </FieldArray>
              {values.address.length === 0 && (
                <FormErrorMessage mt="20px">
                  {String(errors.address)}
                </FormErrorMessage>
              )}
            </FormControl>
          )}
        </Field>
      </Box>
    </>
  );
}
