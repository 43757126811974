import {
  Box,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetJobSpecsMutation } from 'store/constant.slice';
import { getJob } from 'store/jobs.slice';

export const PaidJobModal = ({ isOpen, onClose }: any) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { jobData } = useSelector((state: any) => state.jobs);
  const { job_specs } = useSelector((state: any) => state.constants);

  const [reqSpecs] = useGetJobSpecsMutation();

  useEffect(() => {
    if (isOpen) {
      reqSpecs({});
    }
  }, [isOpen]);

  const onSubmit = () => {
    dispatch(getJob({ hasPaidJob: false }));
    onClose();
    toast({
      title: 'The tracking has been enabled.',
      status: 'success',
      duration: 3000,
      position: 'top',
    });
  };

  const placeHolderClick = (holder: string) => {
    console.log(holder);
    var doc = new DOMParser().parseFromString(holder, 'text/html');
    var element = doc.body;
    var tempElement = document.createElement('div');

    function copyStylesRecursively(
      sourceElement: Element,
      targetElement: Element
    ) {
      Array.from(sourceElement.childNodes).forEach((child: Node) => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          const childElement = child as HTMLElement;
          const newChild = document.createElement(childElement.tagName);
          const styles = window.getComputedStyle(childElement);

          // Apply all computed styles to the new child
          Array.from(styles).forEach((style) => {
            newChild.style.setProperty(style, styles.getPropertyValue(style));
          });

          targetElement.appendChild(newChild);
          copyStylesRecursively(childElement, newChild);
        } else if (child.nodeType === Node.TEXT_NODE) {
          const textNode = child as Text;
          const span = document.createElement('span');
          const parentStyles = window.getComputedStyle(sourceElement);

          Array.from(parentStyles).forEach((style) => {
            span.style.setProperty(style, parentStyles.getPropertyValue(style));
          });

          span.textContent = textNode.textContent;
          targetElement.appendChild(span);
        }
      });
    }

    copyStylesRecursively(element, tempElement);

    var container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    container.appendChild(tempElement);
    document.body.appendChild(container);

    var range = document.createRange();
    range.selectNodeContents(container);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    document.body.removeChild(container);
    selection.removeAllRanges();

    // navigator.clipboard.writeText(doc.body.textContent);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const handleClose = () => {
    onClose();
    dispatch(getJob({ hasPaidJob: false }));
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader sx={{ color: '#6B46C1', fontSize: '18px' }}>
            Post Job on Linkedin
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack sx={{ py: '18px', pt: 0, fontSize: '16px' }}>
              <Flex sx={{ gap: '15px', alignItems: 'center' }}>
                <Box sx={{ width: '120px', fontWeight: 600 }}>
                  Post in Linkedin
                </Box>
                <Link
                  href={`https://www.linkedin.com/talent/job-posting/post?t_j_id=${jobData.id}`}
                  target="_blank"
                >
                  https://www.linkedin.com/talent/job-posting/post?t_j_id=
                  {jobData.id}
                </Link>
              </Flex>
              <Flex sx={{ gap: '15px', alignItems: 'center' }}>
                <Box sx={{ width: '120px', fontWeight: 600, fontSize: '16px' }}>
                  Client Industry
                </Box>
                <Box>
                  {
                    job_specs?.filter(
                      (val: any) =>
                        val.id ===
                        ((jobData?.jobAds || jobData?.jobads) &&
                          jobData?.client?.spec_id)
                    )[0]?.specification
                  }
                </Box>
              </Flex>
            </Stack>

            <Divider />

            <Box mt="29px">
              <Flex justifyContent="space-between" alignItems="center">
                <Box sx={{ fontWeight: 600, fontSize: '16px' }}>
                  Job Ad Description
                </Box>
                {(jobData?.jobAds || jobData?.jobads) &&
                  (jobData?.jobAds?.[0]?.description ||
                    jobData?.jobads?.[0]?.description) && (
                    <Button
                      leftIcon="copy"
                      onClick={() =>
                        jobData?.jobAds?.[0]?.description
                          ? placeHolderClick(jobData?.jobAds?.[0]?.description)
                          : null
                      }
                      sx={{
                        height: 'fit-content',
                        p: '5px 10px',
                        fontSize: '10px',
                      }}
                    >
                      Copy Description
                    </Button>
                  )}
              </Flex>
              <Box mt="16px">
                {(jobData?.jobAds || jobData?.jobads) &&
                (jobData?.jobAds?.[0]?.description ||
                  jobData?.jobads?.[0]?.description)
                  ? parse(
                      jobData?.jobAds?.[0]?.description ||
                        jobData?.jobads?.[0]?.description
                    )
                  : 'No Job Ad Description'}
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex gap="10px">
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit" variant="solid" onClick={onSubmit}>
                Mark as Done
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
