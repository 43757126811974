import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import MyClientsTable from './MyClients/Table';
import AllClientsTable from './AllClients/Table';
import ContactsClientsTable from './Contacts/Table';
import { useDispatch, useSelector } from 'react-redux';
import { navClient, useViewClientMutation } from 'store/client.slice';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

const tabItem = [
  { id: 0, title: 'my-clients', display: 'My Clients' },
  { id: 1, title: 'all-clients', display: 'All Clients' },
  { id: 2, title: 'contacts', display: 'Contacts' },
];

export default function ClientsPage() {
  const dispatch = useDispatch();
  const params = useParams();

  document.documentElement.dir = 'ltr';

  const naviget = useNavigate();

  const { clientNav } = useSelector((state: any) => state.clients);
  const activeTab = useMemo(() => {
    const tab = tabItem.find((tab) => tab?.title === params.clientsTab);
    if (tab?.title) {
      dispatch(navClient({ clientNav: tab?.title }));
    }
    return tab;
  }, [params.clientsTab]);

  const [reqClient] = useViewClientMutation();

  const {
    isOpen: isOpenMyClients,
    onOpen: onOpenMyClients,
    onClose: onCloseMyClients,
  } = useDisclosure();

  const tabChange = (tab: number) => {
    const selectedTab = tabItem[tab];
    naviget(`/clients/${selectedTab.title}`);
  };

  useEffect(() => {
    const fetch = async () => {
      await reqClient({ id: params.clientsId });
    };

    if (params.clientsTab === 'my-clients' && params.clientsId) {
      fetch();
      onOpenMyClients();
    }
  }, [params.clientsId, isOpenMyClients]);

  return (
    <>
      <Tabs colorScheme="purple" onChange={tabChange} index={activeTab.id}>
        <TabList fontSize="md" fontWeight="bold" mx={10}>
          {tabItem.map((tab) => (
            <Tab _focus={{ boxShadow: 'none !important' }}>{tab.display}</Tab>
          ))}
        </TabList>

        <Box sx={{ py: 3, px: 4 }}>
          <>
            {activeTab.title === 'my-clients' && (
              <MyClientsTable
                isOpen={isOpenMyClients}
                onOpen={onOpenMyClients}
                onClose={onCloseMyClients}
              />
            )}
          </>
          <>{activeTab.title === 'all-clients' && <AllClientsTable />}</>
          <>{activeTab.title === 'contacts' && <ContactsClientsTable />}</>
        </Box>
      </Tabs>
    </>
  );
}
