import React from 'react';

interface RenderIfProps {
  condition: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export default function RenderIf({
  condition,
  children,
  fallback = null,
}: RenderIfProps) {
  return <>{condition ? children : fallback}</>;
}
