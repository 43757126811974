import { apiSlice } from 'store/api.slice';
import {
  CountryType,
  EducationType,
  JobSpecicationType,
  LeadSourceType,
  ExperienceLevelType,
  EmployeeType,
  CompensationType,
  WorkTypeType,
  SkillsType,
  DefaultPitchType,
  TagsType,
  AllConstantsType,
  CandidateStatusInterface,
} from './common.type';
import { ApiResponse, PaginatedResponse } from 'services/type';

export const constantAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountries: builder.query<Array<CountryType>, {}>({
      query: () => ({
        url: `/constants/countries`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Countries'],
      transformResponse: (result: ApiResponse<CountryType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchLeadSources: builder.query<Array<LeadSourceType>, {}>({
      query: () => ({
        url: `/constants/lead-source`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Lead-Sources'],
      transformResponse: (result: ApiResponse<LeadSourceType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchJobSpecifications: builder.query<Array<JobSpecicationType>, {}>({
      query: () => ({
        url: '/constants/job-specifications',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Job-Specifications'],
      transformResponse: (result: ApiResponse<JobSpecicationType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchEducation: builder.query<Array<EducationType>, {}>({
      query: () => ({
        url: '/constants/educations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Education'],
      transformResponse: (result: ApiResponse<EducationType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchExperienceLevel: builder.query<Array<ExperienceLevelType>, {}>({
      query: () => ({
        url: '/constants/experience-level',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Experience-level'],
      transformResponse: (result: ApiResponse<ExperienceLevelType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchEmployeeType: builder.query<Array<EmployeeType>, {}>({
      query: () => ({
        url: '/constants/employment-types',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Employee-Type'],
      transformResponse: (result: ApiResponse<EmployeeType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchCompensation: builder.query<Array<CompensationType>, {}>({
      query: () => ({
        url: '/constants/compensations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Compensation'],
      transformResponse: (result: ApiResponse<CompensationType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchWorkType: builder.query<Array<WorkTypeType>, {}>({
      query: () => ({
        url: '/constants/worktypes',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Work-Type'],
      transformResponse: (result: ApiResponse<WorkTypeType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchSkills: builder.query<
      PaginatedResponse<SkillsType> | null,
      { query: string; take: number; page?: number }
    >({
      query: (data) => ({
        url: `/skills`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Skills'],
      transformResponse: (
        result: ApiResponse<PaginatedResponse<SkillsType>>
      ) => {
        return result.success ? result.data : null;
      },
    }),

    fetchDefaultPitches: builder.query<DefaultPitchType[], {}>({
      query: () => ({
        url: `/pitch/default`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      providesTags: ['Default-Pitches'],
      transformResponse: (result: ApiResponse<DefaultPitchType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchPlaceholderPair: builder.query<
      Record<string, string | number>,
      { contact_id?: number; job_id?: number; candidate_id?: number }
    >({
      query: (data) => ({
        url: `/placeholder/pair`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Placeholder-Pair'],
      transformResponse: (
        result: ApiResponse<Record<string, string | number>>
      ) => {
        return result.success ? result.data : {};
      },
    }),
    fetchSearchTags: builder.query<
      TagsType[],
      { tag: string; is_private: boolean }
    >({
      query: (data) => ({
        url: `/tags/search`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Tags-Search'],
      transformResponse: (result: ApiResponse<TagsType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchTagList: builder.query<
      PaginatedResponse<TagsType>,
      { page: number; take: number; query: string }
    >({
      query: (data) => ({
        url: `/tags/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Tags'],
      transformResponse: (result: ApiResponse<PaginatedResponse<TagsType>>) => {
        return result.success
          ? result.data
          : {
              currentPage: 0,
              data: [],
              lastPage: 0,
              count: 0,
              nextPage: 0,
              other: null,
              prevPage: null,
              statusCode: '',
            };
      },
    }),
    fetchCandidateStatus: builder.query<CandidateStatusInterface[], {}>({
      query: () => ({
        url: `/constants/candidate-status`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Candidate-Status'],
      transformResponse: (result: ApiResponse<CandidateStatusInterface[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchAllConstants: builder.query<AllConstantsType, {}>({
      query: () => ({
        url: `/constants/all-constants`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['All-Constants'],
      transformResponse: (result: ApiResponse<AllConstantsType>) => {
        return result.success
          ? result.data
          : {
              candidate_source: [],
              candidate_status: [],
              candidate_work_status: [],
              client_types: [],
              compensations: [],
              contact_types: [],
              educations: [],
              employment_types: [],
              experience_level: [],
              job_status: [],
              lead_source: [],
              work_types: [],
              mail_box: { email: '', id: 0, password: '' },
            };
      },
    }),
  }),
});

export const {
  useFetchCountriesQuery,
  useFetchLeadSourcesQuery,
  useFetchJobSpecificationsQuery,
  useFetchEducationQuery,
  useFetchEmployeeTypeQuery,
  useFetchCompensationQuery,
  useFetchExperienceLevelQuery,
  useFetchWorkTypeQuery,
  useFetchSkillsQuery,
  useFetchDefaultPitchesQuery,
  useLazyFetchDefaultPitchesQuery,
  useFetchPlaceholderPairQuery,
  useLazyFetchPlaceholderPairQuery,
  useFetchSearchTagsQuery,
  useFetchTagListQuery,
  useFetchAllConstantsQuery,
  useFetchCandidateStatusQuery,
} = constantAPI;
