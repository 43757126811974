import { apiSlice } from 'store/api.slice';
import { ApiResponse } from 'services/type';
import { appError } from 'store/app.slice';

export const candidateMutation = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteCandidate: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Candidates-List', id: id },
              { type: 'Candidates-Pinned-Filter', id: 'List' },
            ]
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    hideCandidate: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/hide-toggle/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Candidates-List', id: id },
              { type: 'Candidates-Pinned-Filter', id: 'List' },
            ]
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkRejectCandidates: builder.mutation<
      any,
      { data: { reason: number; idList: number[] } }
    >({
      query: ({ data }) => ({
        url: `/candidates/bulk-reject`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      invalidatesTags: (result, error, { data: { idList } }) =>
        result && !error
          ? idList.map((id) => ({ type: 'Candidates-List', id: `${id}` }))
          : [],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    rejectCandidate: builder.mutation<
      any,
      { data: { reason: number }; id: number }
    >({
      query: ({ data, id }) => ({
        url: `/candidates/reject/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
    }),
    mergeCandidates: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/merge/`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Candidates-List', id: 'List' }] : [],
    }),
    unrejectCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/unreject/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error ? [{ type: 'Candidates-By-Id', id }] : [],
    }),
    bulkUnrejectCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-unreject`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error
          ? [
              { type: 'Candidates-List', id: 'List' },
              { type: 'Candidates-Pinned-Filter', id: 'List' },
              { type: 'Candidates-Group-List', id: 'List' },
            ]
          : [],
    }),
    changeCandidateSubStatus: builder.mutation<
      any,
      { data: { sub_id: number }; id: number }
    >({
      query: ({ data, id }) => ({
        url: `/candidates/sub-status/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      // invalidatesTags: (result, error, { id }) =>
      //   result && !error
      //     ? [
      //         { type: 'Candidates-List', id: 'List' },
      //         { type: 'Candidates-Pinned-Filter', id: 'List' },
      //         { type: 'Candidates-Group-List', id: 'List' },
      //         { type: 'Candidates-By-Id', id },
      //       ]
      //     : [],
    }),
  }),
});

export const {
  useDeleteCandidateMutation,
  useHideCandidateMutation,
  useBulkRejectCandidatesMutation,
  useRejectCandidateMutation,
  useBulkUnrejectCandidateMutation,
  useMergeCandidatesMutation,
  useUnrejectCandidateMutation,
  useChangeCandidateSubStatusMutation,
} = candidateMutation;
