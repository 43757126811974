import {
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Text,
  Icon,
  AccordionPanel,
} from '@chakra-ui/react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

export default function FilterAccordianItem({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <AccordionItem bgColor="#fff" border={0} p="8px" borderRadius="10px">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _expanded={{ color: 'primary.800', pb: '0px' }}
            _focus={{ border: 'none' }}
            fontWeight="600"
            _hover={{ bg: 'none' }}
          >
            <Flex
              sx={{
                gap: '5px',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '21.82px',
                color: '#2B2D42',
                w: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text>{title}</Text>
              <Box>
                <Icon
                  as={isExpanded ? FaAngleDown : FaAngleUp}
                  w="16px"
                  h="16px"
                  color="#6930CA"
                />
              </Box>
            </Flex>
          </AccordionButton>
          <AccordionPanel py="8px">{children}</AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}
