import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { debounce } from 'lodash';
import { number } from 'yup';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Icon,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';

import AtsSelect from '../AtsSelect';
import FilterList from '../AtsSelectCandidateView/filterlist';
import AtsCraetableSelDyn from '../AtsCreatabeSelect/Dyanmic';
import AtsSelectCandidateView from '../AtsSelectCandidateView';

import {
  useGetCandidateSourceMutation,
  useGetCandidatetatusMutation,
  useGetCandidateWorkStatusMutation,
} from 'store/constant.slice';
import {
  initialCandidatePgBtn,
  listCandidates,
  setloadingCandidate,
  useListCandidatesMutation,
  usePostJobViewCountMutation,
} from 'store/candidates.slice';
import { useJobTitleSearchMutation } from 'store/jobs.slice';
import {
  useSearchGeoTypesMutation,
  useWildSearchGeoTypesMutation,
} from 'store/geo.slice';
import { useTagListMutation } from 'store/tag.slice';

import jobCandidateStatus from 'constants/jobCandidateStatus';

import { AutoResizeTextarea } from '../AutoResizeTextarea';
import { getFilters, listFilters } from 'store/candidatefilter.slice';
import LoadingPage from '../Loading';

import { useUserListMutation } from 'store/user.slice';
import ViewCandidateTab from '../../../modules/Jobs/Layout/ViewCandidatesModule/VerticalFilter/Tab';
import viewCandidateConfig from '../../../modules/Jobs/Layout/ViewCandidatesModule/VerticalFilter/config';
import candidatePayload from '../../../modules/Jobs/Layout/ViewCandidatesModule/VerticalFilter/config/param';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import delay from 'utils/delay';
import { JobCandidateStatus } from './components/JobCandidateStatus';
import { SearchBar } from '../Global/SearchBar';
import { CheckboxLayout } from '../Global/Checkbox';
interface optionInterface {
  label: string;
  value: any;
}

interface CandidatesFilterBarProps {
  isClear?: boolean;
}

export default function CandidatesFilterBar({
  isClear,
}: CandidatesFilterBarProps) {
  const dispatch = useDispatch();
  const urlParam = useParams();

  document.documentElement.dir = 'ltr';

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { UserPgBtn } = useSelector((state: any) => state.user);
  const { candidate_status, candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { candidate_filters, params } = useSelector(
    (state: any) => state.candidateFilters
  );
  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [reqSubStatus, resSubStatus] = useGetCandidatetatusMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reJobs] = useJobTitleSearchMutation();
  const [reqTags, resTags] = useTagListMutation();
  const [reqGeo, resGeo] = useWildSearchGeoTypesMutation();
  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqUserList] = useUserListMutation();
  const [reqCandidates] = useListCandidatesMutation();

  const { onClose } = useDisclosure();

  let intialOption: optionInterface[] = [];
  let tagForm = {
    tag: '',
    is_private: false,
  };

  const candidatedFilterData: string | null =
    localStorage.getItem('candidatesFilter');

  let filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  //fill the candidatesFilter storage if empty
  useEffect(() => {
    if (candidatedFilterData === null && filter) {
      localStorage.setItem('candidatesFilter', JSON.stringify(filter));
    }
  }, [candidatePgBtn]);

  const [isBool, setIsBool] = useState(filter?.boolean || false);
  const [hasResume, setHasResume] = useState(filter?.has_resume || false);

  const job_id =
    urlParam.jobsTabId !== 'candidate-pool' ? Number(urlParam.jobId) : null;
  const excluded_job_id =
    urlParam.jobsTabId === 'candidate-pool' ? Number(urlParam.jobId) : null;
  useEffect(() => {
    // search;
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      search: filter.search,
      excluded_job_id: excluded_job_id,
    };
    if (!isBool) sumbitList(newParam);
  }, [isBool]);

  const [titleInclude, setTitleInclude] = useState(filter?.job_filter_include);
  const [tagPrivate, setTagPrivate] = useState('1');
  const [defOption, setDefOption] = useState(intialOption);
  const [candSource, setCandSource] = useState([]);
  const [candSubSource, setCandSubSource] = useState([]);
  const [candSourceVisible, setCandSourceVisible] = useState(false);
  const [userSource, setUserSource] = useState([]);
  const [booleanSearch, setBoleanSearch] = useState(filter?.search || '');

  const [searchCandidate, setSearchCandidate] = useState(filter?.query || '');

  const abortControllerRef = useRef<AbortController | null>(null);

  const handleSearchCandidate = debounce((e: any) => {
    let query = e.target.value;

    let payload = {
      ...filter,
      page: 1,
      query: query,
    };

    if (query.length >= 3 || query === '') {
      localStorage.setItem('candidatesFilter', JSON.stringify(payload));
      dispatch(listCandidates({ candidatePgBtn: payload }));
    }
  }, 300);

  const resumeOptions = [
    { label: 'All Time', value: 0 },
    { label: 'Last Week', value: 1 },
    { label: 'Last Month', value: 2 },
    { label: '0-3 months', value: 3 },
    { label: '3-6 months', value: 4 },
    { label: '6-12 months', value: 5 },
    { label: 'More than a year', value: 6 },
  ];

  const [filterActive, setFilterActive] = useState(filter?.filter);
  const [filterDefValue, setFilterDefValue] = useState<any>([
    // {
    // value: null,
    // label: 'All Candidates',
    // group: false,
    // },
  ]);
  const [location, setLocation] = useState<any>(filter?.location);
  const [zipCode, setZipCode] = useState<any>(filter?.zip_code);

  const [tags, setTags] = useState<any>(filter?.tags);

  const [jobTitle, setJobTitle] = useState<any>(filter?.job_filter);
  const [visibleJS, setVisibleJS] = useState(false);
  const [jsOption, setJsOption] = useState([]);
  const [defTags, setDefTags] = useState([]);
  const [itemLocation, setItemLocation] = useState([]);
  const [currentName] = useState('');
  const [visibleViewFilter, setVisibleFilter] = useState(
    urlParam?.jobId ? false : true
  );
  const [search, setSearch] = useState<any>(filter?.search);

  const [isLoading, setIsLoading] = useState(false);
  const [prevParam, setPrevParam] = useState(filter);

  const workStatusOptions = candidate_work_status.map((item: any) => ({
    value: item.id,
    label: item.status,
  }));

  const sumbitList = async (param: any) => {
    let subs: any = [];
    subs['submits'] = [1];
    subs['screen'] = [2];
    subs['interviews'] = [3];
    subs['offers'] = [4];
    subs['hired'] = [5];
    subs['rejected'] = [6];
    subs['blasted'] = [8];

    let status;
    if (urlParam?.jobsTabId) {
      switch (urlParam?.jobsTabId) {
        case 'screen':
          status = [2];
          break;
        case 'in-progress':
          status = urlParam?.jobsTabNav ? subs[urlParam.jobsTabNav] : [8];
          break;
        case 'hired':
          status = [5];
          break;
        case 'rejected':
          status = [6];
          break;
        default:
          status = null;
      }
    } else {
      status = null;
    }
    let newParam = {
      ...candidatePgBtn,
      page: param.page,
      take: param.take,
      query: param.query,
      search: param.search,
      boolean: param.boolean,
      job_filter: param.job_filter,
      job_filter_include: param.job_filter_include,
      location: param.location,
      tags: param.tags,
      skills: param.skills,
      experience: param.experience,
      work_status: param.work_status,
      job_status: param.job_status,
      resume_updated_on: param.resume_updated_on,
      // job_id: job_id,
      // excluded_job_id: excluded_job_id,
      filter: param.filter,
      source: param.source,
      sub_source: param.sub_source,
      status: status,
      job_id: param.job_id,
      zip_code: param.zip_code,
      has_resume: param.has_resume,
    };
    await dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );

    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
  };

  useEffect(() => {
    setPrevParam(candidatePgBtn);
  }, [candidatePgBtn]);

  useEffect(() => {
    if (candidatePgBtn !== prevParam) {
      setIsLoading(false);
    }
  }, [candidatePgBtn, prevParam]);

  // const formatLocOption = async (loc: any) => {
  //   let options: any = [];

  //   await Promise.all(
  //     loc.map(async (item: any) => {
  //       const value = currentName === 'city' ? item.city : item.postal_code;
  //       options.push({
  //         // city: item.city,
  //         // country: item.country,
  //         // postal_code: item.postal_code,
  //         // country_code: item.country_code,
  //         // state: item.state,
  //         value: 1,
  //         label: [item.city, item.state, item.country].filter(Boolean).join(', '),
  //         // id: number,
  //       });
  //     })
  //   );

  //   return await options;
  // };

  const formatLocOption = async (loc: any) => {
    let options: any = [];
    await Promise.all(
      loc.map(async (item: any) => {
        // const value = currentName === 'city' ? tag.city : tag.postal_code;
        options.push({
          label: [item.city, item.state, item.country]
            .filter(Boolean)
            .join(', '),
          value: item.id,
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
        });
      })
    );

    return await options;
  };

  const formatZipCodeOption = async (loc: any) => {
    let options: any = [];
    await Promise.all(
      loc?.map(async (item: any) => {
        // const value = currentName === 'city' ? tag.city : tag.postal_code;
        //   {
        //     "postal_code": "00603",
        //     "street": "",
        //     "city": "Aguadilla",
        //     "state": "Puerto Rico",
        //     "province": "Aguadilla",
        //     "country": "United States",
        //     "country_code": "us"
        // }
        options.push({
          label: [item.postal_code, item.city, item.state, item.country]
            .filter(Boolean)
            .join(', '),
          value: item.postal_code,
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
        });
      })
    );

    return await options;
  };

  const formatTagOption = async (tags: any) => {
    let options: optionInterface[] = [];
    await Promise.all(
      tags.map(async (tag: any) => {
        options.push({
          label: tag.tag_name,
          value: tag.id,
        });
      })
    );

    return await options;
  };
  const [itemZipCodes, setItemZipCodes] = useState([]);

  const promiseLocOptions = debounce(
    (inputValue: string, callback: (options: any[]) => void) => {
      const data = {
        search: inputValue,
        currentName,
      };
      reqGeo(data).then((res: any) => {
        const getData = async () => {
          const option = await formatLocOption(res?.data?.data);
          setItemLocation(option);
          callback(option);
        };
        getData().catch(console.error);
      });
    },
    500
  );

  const [reqGeoSearch, resGeoSearch] = useSearchGeoTypesMutation();

  const promiseZipCodeOptions = debounce(
    (inputValue: string, callback: (options: any[]) => void) => {
      const data = {
        search: inputValue,
        column: 'postal_code',
      };
      if (inputValue !== '') {
        reqGeoSearch(data).then((res: any) => {
          const getData = async () => {
            const option = await formatZipCodeOption(res?.data?.data);
            setItemZipCodes(option);
            callback(option);
          };
          getData().catch(console.error);
        });
      }
    },
    500
  );

  const jobTitleChange = debounce(async (e: any) => {
    e.preventDefault();

    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      has_resume: hasResume,
      job_filter: e.target.value,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    sumbitList(newParam);
  }, 2000);

  const tagChange = async (value: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      has_resume: hasResume,
      tags: value.map((val: any) => val.value),
      defTags: value,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    sumbitList(newParam);
  };

  const hasResumeChange = async (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      // boolean: isBool,
      has_resume: e.target.checked,
      // defTags: defTags,
      job_id: urlParam?.jobsTabId != 'search' ? job_id : null,
      // excluded_job_id: excluded_job_id,
    };
    setHasResume(e.target.checked);
    sumbitList(newParam);
  };

  const resumeUpdatedOnChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      has_resume: hasResume,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      resume_updated_on: e.label,
    };
    sumbitList(newParam);
  };

  const workStatusChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      has_resume: hasResume,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      work_status: e.map((val: any) => val.value),
    };
    sumbitList(newParam);
  };

  const sourceChange = (e: any) => {
    let newParam = {
      ...candidatePgBtn,
      page: 1,
      // boolean: isBool,
      defTags: defTags,
      // job_id: job_id,
      // excluded_job_id: excluded_job_id,
      source: e.value,
      sub_source: null,
    };
    sumbitList(newParam);
  };

  const subSourceChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      has_resume: hasResume,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      sub_source: e.value,
    };
    sumbitList(newParam);
  };

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      let zipOption: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName === 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          // value: value,
          value: item.id,
          label: `${item.state}, ${item.country}`,
          id: number,
        });
        zipOption.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          // value: value,
          value: item.postal_code,
          label: [item.postal_code, item.city, item.state, item.country]
            .filter(Boolean)
            .join(', '),
          id: number,
        });
      });
      setItemLocation(optios);
      setItemZipCodes(zipOption);
    } else {
      setItemLocation([]);
      setItemZipCodes([]);
    }
  };

  // RESET FILTERS WHEN CHANGING TO A DIFFERENT MODULE
  // useEffect(() => {
  //   clearFilterPanel();
  // }, [urlParam['*']]);

  const urlChangeStatus = () => {
    if (urlParam?.jobsTabNav) {
      const filters: any = {
        screen: 2,
        hired: 5,
        rejected: 6,
        submits: 1,
        interviews: 3,
        offers: 4,
        blasted: 8,
      };
      let selected: any = filters[urlParam?.jobsTabNav];

      if (selected) {
        setVisibleJS(true);
        setOptionStatus(selected);
      }
    } else {
      setVisibleJS(false);
    }
  };

  useEffect(() => {
    if (params === 'view-candidates') {
      const clearSearch = {
        ...candidatePgBtn,
        search: '',
      };
      dispatch(listCandidates({ candidatePgBtn: clearSearch }));
      setSearch('');
    } else {
      const searchValue = filter.search;

      setSearch(searchValue);
    }

    if (urlParam?.jobsTabNav) {
      urlChangeStatus();
    }
  }, []);

  const setOptionStatus = (id: any) => {
    setJsOption([]);

    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (id === item?.id) {
          if (item?.sub?.length > 0) {
            let option: any = [];
            option.push({
              label: 'All',
              value: null,
            });
            item?.sub?.map((sub: any, key: number) => {
              option.push({
                label: sub.sub_status,
                value: sub.id,
              });
            });
            groups.push({
              label: item.status,
              options: option,
            });
          }
          setJsOption(groups);
        }
      });
    }
  };

  const booleanSearchChange = async (event: any) => {
    // event.preventDefault();
    let newParam = {
      ...filter,
      page: 1,
      search: booleanSearch,
      boolean: true,
      has_resume: hasResume,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    sumbitList(newParam);
  };

  const jsChange = (event: any) => {
    let {
      blastedCountParam,
      screenCountParam,
      submitCountParam,
      interviewCountParam,
      offersCountParam,
      hiredCountParam,
      rejectedCountParam,
    } = viewCandidateConfig(candidatePgBtn);

    let status = candidatePgBtn.status;
    let job_status = event.value;
    if (job_status == null) {
      if (urlParam.jobsTabNav === 'blasted') {
        job_status = blastedCountParam.job_status;
        status = blastedCountParam.status;
      }
      if (urlParam.jobsTabNav === 'screen') {
        job_status = null;
        // job_status = screenCountParam.job_status;
        status = screenCountParam.status;
      }
      if (urlParam.jobsTabNav === 'submits') {
        job_status = submitCountParam.job_status;
        status = submitCountParam.status;
      }
      if (urlParam.jobsTabNav === 'interviews') {
        job_status = interviewCountParam.job_status;
        status = interviewCountParam.status;
      }
      if (urlParam.jobsTabNav === 'offers') {
        job_status = offersCountParam.job_status;
        status = offersCountParam.status;
      }
      if (urlParam.jobsTabNav === 'hired') {
        job_status = hiredCountParam.job_status;
        status = hiredCountParam.status;
      }
      if (urlParam.jobsTabNav === 'rejected') {
        job_status = rejectedCountParam.job_status;
        status = rejectedCountParam.status;
      }
    }

    let newParam = {
      // ...filter,
      ...candidatePgBtn,
      page: 1,
      search: search,
      has_resume: hasResume,
      boolean: isBool,
      defTags: defTags,
      job_status,
      status,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));

    if (params === 'view-candidates') {
      newParam = { ...newParam, filter: null };
    }

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );

    if (urlParam.jobsTabId) {
      setIsLoading(true);
    }
    // sumbitList(newParam);
  };

  const filterViewChange = (id: any) => {
    let newParam = {
      ...candidatePgBtn,
      query: '',
      page: 1,
      defTags: defTags,
      job_id: null,
      zip_code: null,
      excluded_job_id: excluded_job_id,
      filter: id,
      boolean: isBool,
      has_resume: hasResume,
    };

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    ); //either table or tablev2 will be trigger as long as you pass the parameter here
    // sumbitList(newParam);
  };

  const filterPinChange = async (e: any) => {
    // console.log({ e });
    let id = e.label == 'All Candidates' ? null : e.value;
    // let id = e.value;
    // console.log('filterPinChange', e);
    // COMMENTING THIS FILTER VIEW CHANGE SINCE IT TRIGGERS CANDIDATES LIST
    // filterViewChange(id);
    // console.log({ id });
    const param = {
      ...candidatePgBtn,
      query: '',
      job_id: null,
      zip_code: null,
      filter: id,
    };

    await dispatch(getFilters({ group: e.group }));
    await dispatch(listCandidates({ candidatePgBtn: param }));
    if (!e.group) {
      dispatch(listFilters({ filter: e.value, group: e.group }));
    }

    setFilterDefValue([e]);
    resetFilters();
  };

  const resetFilters = () => {
    const defaultFilters = {
      page: 1,
      take: 50,
      query: '',
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [] as any,
      skills: [] as any,
      experience: [] as any,
      work_status: null as any,
      job_id: null as any,
      excluded_job_id: null as any,
      status: null as any,
      job_status: null as any,
      resume_updated_on: null as any,
      zip_code: null as any,
      // filter: filter.filter,
      search: '',
      boolean: true,
      reject_reason: null as any,
      source: null as any,
      sub_source: null as any,
      has_resume: false,
    };
    setStatusIncludePayload([]);
    setSearchCandidate('');
    setIsBool(true);
    setHasResume(false);
    setLocation('');
    setZipCode(null);
    setTitleInclude('Current');
    setJobTitle('');
    setTagPrivate('1');
    setSearch('');
    setTags([]);
    setCandSourceVisible(false);
    setBoleanSearch('');

    return defaultFilters;
  };

  const clearFilterPanel = async () => {
    let filterData = {
      ...resetFilters(),
      filter: filter.filter,
    };

    if (urlParam?.jobsTabId == 'in-progress') {
      const payloads = candidatePayload(urlParam.jobsTabNav, candidatePgBtn);

      filterData = {
        ...resetFilters(),
        job_id: Number(urlParam?.jobId) || null,
        job_status: payloads?.job_status || null,
        status: payloads?.status || [8],
        filter: filter.filter,
        excluded_job_id: null,
      };
    } else if (urlParam?.jobsTabId == 'search') {
      filterData = {
        ...filterData,
        excluded_job_id: Number(urlParam.jobId) || null,
        job_id: null,
      };
    }
    await dispatch(
      listCandidates({
        candidatePgBtn: filterData,
      })
    );
    sumbitList(filterData);
  };

  const url = window.location.pathname;

  useEffect(() => {
    if (url.includes('/candidates/')) {
      dispatch(listFilters({ params: urlParam['*'] }));
    }

    if (url.includes('/jobs/')) {
      dispatch(listFilters({ params: 'jobs' }));
    }
  }, [urlParam]);

  useEffect(() => {
    if (isClear) {
      clearFilterPanel();
    }
  }, [isClear]);

  useEffect(() => {
    if (params) {
      if (url.includes('/candidates/')) {
        if (params !== urlParam['*']) {
          clearFilterPanel();
        }
      }
      if (url.includes('/jobs/')) {
        if (params !== 'jobs') {
          clearFilterPanel();
        }
      }
    }
  }, [params]);

  const [reqPostJobViewCount] = usePostJobViewCountMutation();

  const { candidatePage } = useSelector((state: any) => state.candidates);

  useEffect(() => {
    if (urlParam?.jobId) {
      reqPostJobViewCount({ id: urlParam?.jobId });
    }
  }, [urlParam?.jobId, urlParam?.jobsTabNav, candidatePage.count]);

  useEffect(() => {
    if (urlParam?.jobsTabNav) {
      urlChangeStatus();
    }
  }, [urlParam?.jobsTabNav]);

  useEffect(() => {
    if (urlParam?.jobsTabNav) {
      urlChangeStatus();
    }
  }, [candidate_status]);

  useEffect(() => {
    if (filter.filter) {
      setFilterActive(filter.filter);
      dispatch(listFilters({ filter: filter.filter }));
    }
  }, [filter.filter]);

  useEffect(() => {
    if (candidate_filters.length > 0 && candidatePgBtn?.filter) {
      let activeFilter = candidate_filters.find(
        (filter: any) => filter.id == candidatePgBtn?.filter
      );
      if (activeFilter?.group_by_job) {
        dispatch(listFilters({ group: activeFilter.group_by_job }));
      }
    }
  }, [candidate_filters, candidatePgBtn?.filter]);

  const milesOptions = [
    { label: '1 mile', value: 1 },
    { label: '5 miles', value: 5 },
    { label: '10 miles', value: 10 },
    { label: '25 miles', value: 25 },
    { label: '50 miles', value: 50 },
    { label: '100 miles', value: 100 },
    { label: '200 miles', value: 200 },
    { label: '300 miles', value: 300 },
    { label: '400 miles', value: 400 },
    { label: '500 miles', value: 500 },
  ];

  useEffect(() => {
    if (onClose) {
      dispatch(
        listCandidates({
          candidatePgBtn: initialCandidatePgBtn,
        })
      ); //either table or tablev2 will be trigger as long as you pass the parameter here
    }
  }, [onClose]);

  useEffect(() => {
    const load = async () => {
      await reqTags(tagForm);
      delay(200);
      await reqCanWorkStat({});
      delay(200);
      await reJobs({ query: '' });
      delay(200);
      await reqSubStatus({})
        .then((res: any) => {
          setJsOption([]);

          if (res?.data?.data?.length > 0) {
            let groups: any = [];
            res?.data?.data.map((item: any) => {
              if (item?.sub?.length > 0) {
                let option: any = [];
                option.push({
                  label: 'All',
                  value: null,
                });
                item?.sub?.map((sub: any, key: number) => {
                  option.push({
                    label: sub.sub_status,
                    value: sub.id,
                  });
                });
                groups.push({
                  label: item.status,
                  options: option,
                });
              }
              setJsOption(groups);
            });
          }
        })
        .catch((err: any) => {
          console.warn({ err });
        });
      await urlChangeStatus();
    };
    load();
  }, []);

  const subSource = (sub: any) => {
    let option: any = [];
    if (sub?.length > 0) {
      sub.map((item: any) => {
        option.push({
          label: item?.sub_source,
          value: item?.id,
        });
      });
    }
    setCandSubSource(option);
  };

  useMemo(() => {
    reqUserList({ data: UserPgBtn }).then((res: any) => {
      let option: any = [];
      if (Object.values(res?.data?.data)?.length > 0) {
        Object.values(res?.data?.data).map((item: any) => {
          option.push({
            value: item.id,
            label: [item?.first_name, item?.last_name].join(' '),
          });
        });
      }
      setUserSource(option);
    });
  }, []);

  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: item.source,
            value: item.id,
            sub: item.sub,
          });
        });
      }
      setCandSource(option);
    });
  }, []);

  useEffect(() => {
    if (resTags.isSuccess) {
      const getData = async () => {
        const option = await formatTagOption(resTags.data.data);
        setDefOption(option);
      };
      getData().catch(console.error);
    }
  }, [resTags.isSuccess]);

  useEffect(() => {
    if (
      filter?.filter != candidatePgBtn?.filter &&
      filter?.filter &&
      urlParam.candidatesTabState == 'views'
    ) {
      dispatch(
        listCandidates({
          candidatePgBtn: { ...candidatePgBtn, filter: filter?.filter },
        })
      );
    }
  }, [filter, candidatePgBtn]);

  // Added filterActive
  useEffect(() => {
    setFilterDefValue(
      candidate_filters
        .map((item: any) => {
          return {
            value: item.id,
            label: item.title,
            group: item.group || item.group_by_job,
          };
        })
        .filter((val: any) => val.value === (filter.filter || filterActive))[0]
    );
  }, [filter.filter, candidate_filters, filterActive]);

  useEffect(() => {
    if (urlParam?.jobId) {
      setVisibleFilter(false);
    }

    let status = jobCandidateStatus[urlParam.jobsTabId] || null;

    if (urlParam.jobsTabId === 'in-progress') {
      let subs: any = [];
      subs['submits'] = [1];
      subs['screen'] = [2];
      subs['interviews'] = [3];
      subs['offers'] = [4];
      subs['hired'] = [5];
      subs['rejected'] = [6];
      subs['blasted'] = [8];
      status = urlParam?.jobsTabNav ? subs[urlParam.jobsTabNav] : [1];
    }

    //PREVENTING FOR MULTIPLE CALLS WHEN VIEWING CANDIDATES FROM JOBS
    if (!urlParam.jobId) {
      let newParam = {
        page: filter.page,
        take: filter.take,
        query: filter.query,
        search: filter.search,
        boolean: isBool,
        job_filter: filter.job_filter,
        job_filter_include: filter.job_filter_include,
        location: filter.location,
        zip_code: filter.zip_code,
        tags: filter.tags,
        skills: filter.skills,
        experience: filter.experience,
        work_status: filter.work_status,
        resume_updated_on: filter.resume_updated_on,
        job_id: job_id,
        excluded_job_id: excluded_job_id,
        job_status: filter.job_status,
        status: status,
        filter: filter?.filter,
        reject_reason: filter?.reject_reason,
        source: filter?.source,
        sub_source: filter?.sub_source,
        has_resume: filter?.has_resume,
      };

      // reqCandidates(newParam);
      // console.log("went here to intiial newParam ", newParam)
      dispatch(
        listCandidates({
          candidatePgBtn: newParam,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (candidate_filters.length > 0 && filterActive === null) {
      let min_order = candidate_filters[0]?.pin?.order || 0;
      let first_order_index = 0;
      candidate_filters.map((filter: any, index: number) => {
        if (filter.pin) {
          if (min_order > filter.pin?.order || min_order == 0) {
            min_order = filter.pin?.order;
            first_order_index = index;
          }
        }
      });
      setFilterActive(candidate_filters[first_order_index].id);
      const param = {
        ...candidatePgBtn,
        filter: candidate_filters[first_order_index].id,
      };
      dispatch(listCandidates({ candidatePgBtn: param }));
      dispatch(
        listFilters({
          filter: candidate_filters[first_order_index].id,
          group: candidate_filters[first_order_index].group_by_job,
        })
      );
    }
  }, [candidate_filters]);
  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  useEffect(() => {
    urlChangeStatus();
  }, [resSubStatus.isSuccess]);

  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const {
      query,
      search,
      job_filter,
      job_filter_include,
      location: filterLocation,
      zip_code,
      tags,
      experience,
      work_status,
      job_status,
      resume_updated_on,
      source,
      sub_source,
      has_resume,
    } = filter;

    const nonEmptyValues = [
      query !== '',
      booleanSearch !== '',
      job_filter !== '',
      // job_filter_include !== 'Current',
      filterLocation !== '' && location?.length > 0,
      tags?.length > 0,
      experience?.length > 0,
      work_status !== null && work_status?.length > 0,
      zip_code !== null &&
        zip_code !== undefined &&
        ((zip_code?.code !== null && zip_code?.code !== undefined) ||
          (zip_code?.distance !== null && zip_code?.code !== undefined)),
      // job_status !== null, //since the ui change, this won't be needed
      resume_updated_on !== null,
      source !== null,
      sub_source !== null,
      has_resume,
    ];
    setCount(nonEmptyValues.filter(Boolean)?.length);
  }, [filter, candidatePgBtn, location, booleanSearch]);

  useEffect(() => {
    dispatch(getFilters({ filter_count: count }));
  }, [count]);

  const [tabIndex, setTabIndex] = useState(1);

  const FILTER_INCLUDE = [
    {
      id: 1,
      stat_id: 'Current',
      status:
        filter?.job_filter_include === 'Current'
          ? 1
          : filter?.job_filter_include === 'Current or Past'
          ? 1
          : 1,
      name: 'Current',
    },
    {
      id: 2,
      stat_id: 'Past',
      status:
        filter?.job_filter_include === 'Past'
          ? 1
          : filter?.job_filter_include === 'Current or Past'
          ? 1
          : 0,
      name: 'Past',
    },
  ];

  const [statusIncludePayload, setStatusIncludePayload] = useState([]);

  useEffect(() => {
    if (statusIncludePayload) {
      let newParams = {
        ...candidatePgBtn,
        ...filter,
        page: 1,
        boolean: isBool,
        has_resume: hasResume,
        job_filter_include:
          statusIncludePayload.length === 2
            ? 'Current or Past'
            : statusIncludePayload[0],
        defTags: defTags,
        job_id: job_id,
        excluded_job_id: excluded_job_id,
      };

      sumbitList(newParams);
    }
  }, [statusIncludePayload]);

  useEffect(() => {
    if (urlParam?.candidatesTabState) {
      setTabIndex(urlParam?.candidatesTabState == 'views' ? 1 : 0);
    }
  }, [urlParam?.candidatesTabState]);
  return isLoading ? (
    <LoadingPage />
  ) : (
    <Box
      borderColor="default.borderlight"
      bg="#F7FAFC"
      overflowY="scroll"
      width="300px"
      height="90vh"
      position="relative"
    >
      {visibleViewFilter && (
        <Tabs
          index={tabIndex}
          isFitted
          variant="unstyled"
          onChange={(index) => {
            setTabIndex(index);
            if (index === 0) {
              let filterData = {
                ...resetFilters(),
                filter: null as any,
              };

              dispatch(
                listCandidates({
                  candidatePgBtn: filterData,
                })
              );
              sumbitList(filterData);
              dispatch(getFilters({ group: false, filter: null }));
              dispatch(listCandidates({ candidates: [] }));

              navigate('/candidates/search');
              // dispatch(listFilters({ filter: null, group: false }));
            } else {
              let filterData = {
                ...resetFilters(),
                filter: filter.filter || filterActive,
              };
              dispatch(
                listCandidates({
                  candidatePgBtn: filterData,
                })
              );
              sumbitList(filterData);
              dispatch(
                getFilters({
                  group:
                    filterDefValue?.group ||
                    filterDefValue?.[0]?.group ||
                    false,
                  filter: filter.filter || filterActive,
                })
              );
              navigate('/candidates/views');
            }
          }}
        >
          <TabList
            bg="white"
            sx={{ px: '8px', py: '16px', borderRadius: '10px' }}
          >
            <Flex
              w="100%"
              bg="#FAF6FF"
              borderRadius="10px"
              p="1"
              color="default.gray.1000"
            >
              <Tab
                _focus={{ border: 'none' }}
                _selected={{
                  color: 'primary.800',
                  bg: 'white',
                  borderRadius: '10px',
                  fontWeight: 700,
                }}
                onClick={() => {
                  localStorage.setItem(
                    'candidatesFilter',
                    JSON.stringify(initialCandidatePgBtn)
                  );
                  dispatch(
                    listCandidates({ candidatePgBtn: initialCandidatePgBtn })
                  );
                }}
                w="100%"
              >
                Search
              </Tab>
              <Tab
                _focus={{ border: 'none' }}
                _selected={{
                  color: 'primary.800',
                  bg: 'white',
                  borderRadius: '10px',
                  fontWeight: 700,
                }}
                onClick={() => {
                  localStorage.setItem(
                    'candidatesFilter',
                    JSON.stringify(initialCandidatePgBtn)
                  );
                  dispatch(
                    listCandidates({ candidatePgBtn: initialCandidatePgBtn })
                  );
                }}
                w="100%"
              >
                Views
              </Tab>
            </Flex>
          </TabList>
          <TabPanels mt="10px">
            <TabPanel p="0"></TabPanel>
            <TabPanel p="0">
              <Box
                sx={{
                  bg: '#FFF',
                  borderRadius: '8px',
                  p: '16px',
                }}
              >
                <Box>
                  <AtsSelectCandidateView
                    name="candidate_view"
                    onChange={async (e: any) => {
                      const id = e.label == 'All Candidates' ? null : e.value;
                      await dispatch(getFilters({ group: e.group }));
                      filterViewChange(id);
                      dispatch(listFilters({ filter: id, group: e.group }));
                      setFilterDefValue([e]);
                      setFilterActive(e.value);
                    }}
                    defaultValue={filterDefValue}
                    value={filterDefValue}
                    placeholder="Select View"
                    isActive={filterActive}
                  />
                </Box>
                <FilterList
                  active={filterActive}
                  setFilterActive={setFilterActive}
                  onChange={(e) => {
                    // setFilterDefValue([]);
                    filterPinChange(e);
                  }}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      {visibleJS && <ViewCandidateTab />}

      <Box
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
          my: '8px',
          p: '16px',
        }}
      >
        <Flex
          gap="5px"
          sx={{
            borderBottom: '1px solid #E7EDF4',
            mb: '16px',
            pb: '8px',
            color: '#718096',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '21.82px',
          }}
        >
          Filters{' '}
          {count !== 0 && <Box sx={{ color: 'primary.800' }}>({count})</Box>}
        </Flex>

        <SearchBar
          search={searchCandidate}
          setSearch={setSearchCandidate}
          placeholder="Search by name, phone, email..."
          handleOnChange={handleSearchCandidate}
        />

        {/* Job Candidate Status */}
        {urlParam?.jobsTabId === 'in-progress' &&
          !['blasted', 'submits', 'interviews', 'offers'].includes(
            urlParam.jobsTabNav
          ) && (
            <Box
              sx={{
                pt: '16px',

                '.chakra-select__icon-wrapper': {
                  color: 'primary.800',
                },
              }}
            >
              <Box mb="10px" color="#718096" fontWeight="600" fontSize="14px">
                Job Candidate Status
              </Box>
              <JobCandidateStatus />
            </Box>
          )}
      </Box>

      <Box>
        {/* Location */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={(location || zipCode?.code || zipCode?.distance) && 0}
        >
          <AccordionItem borderColor="transparent" gap="8px">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={LocationPin} /> */}

                    <Box>Location</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box>
                    <Box
                      mb="5px"
                      sx={{
                        '&&& .multiValue': {
                          bg: 'red',
                        },
                      }}
                    >
                      <AtsCraetableSelDyn
                        name="location"
                        value={location}
                        placeholder="Location Search"
                        onChange={(item: any) => {
                          // reqGeo({ item, currentName });
                          setLocation(item);
                          let newParam = {
                            ...filter,
                            page: 1,
                            boolean: isBool,
                            has_resume: hasResume,
                            location: item,
                            zip_code: zipCode,
                            defTags: defTags,
                            job_id: job_id,
                            excluded_job_id: excluded_job_id,
                          };
                          sumbitList(newParam);
                        }}
                        onInputChange={promiseLocOptions}
                        options={itemLocation}
                        isMulti
                        isClearable
                        isLoading={resGeo.isLoading}
                        formatCreateLabel={(userInput: any) =>
                          `Search "${userInput}"`
                        }
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            border: `1px solid ${
                              !location ? '#E7EDF4' : '#6930CA'
                            }`,
                            borderRadius: '6px',
                            bgColor: '#FFFFFF',
                            color: '#6930CA',
                            cursor: 'pointer',
                            h: 'fit-content',
                            boxShadow: 'none',
                            '&:hover': { border: '1px solid #6930CA' },
                            '&:focus': {
                              border: '1px solid #6930CA',
                              boxShadow: 'none',
                              color: '#6930CA',
                            },
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#718096',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#718096',
                          }),
                          multiValue: (styles: any) => ({
                            ...styles,
                            background: '#F9FAFB',
                            borderRadius: '6px',
                            '&& div:first-of-type': {
                              color: '#6930CA',
                              fontWeight: 700,
                            },
                            '&& svg': {
                              color: '#718096',
                            },
                          }),
                        }}
                        components={{
                          Option: ({ label, innerProps }: any) => (
                            <Box onClick={(e) => innerProps.onClick(e)}>
                              <Flex
                                sx={{
                                  fontFamily: 'Proxima Nova Regular',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  p: '12px 24px',
                                  cursor: 'pointer',
                                }}
                                _hover={{
                                  backgroundColor: '#F2EFF9',
                                  color: '#2B2D42',
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                  }}
                                >
                                  {label}
                                </Box>
                              </Flex>
                            </Box>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Flex
                    sx={{
                      gap: '5px',
                      mt: '12px',
                      pb: '4px',
                      w: '100%',
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#2B2D42',
                    }}
                  >
                    Zip Code
                  </Flex>
                  <Box>
                    <Flex
                      mb="5px"
                      sx={{
                        '&&& .multiValue': {
                          bg: 'red',
                        },
                      }}
                      w="100%"
                      p="0"
                    >
                      <Box w="100%">
                        <AtsCraetableSelDyn
                          name="zip_code"
                          value={
                            itemZipCodes.filter((option: any) => {
                              return (
                                option?.value === zipCode?.code &&
                                zipCode?.code !== undefined &&
                                zipCode?.code !== null
                              );
                            })[0] || null
                          }
                          placeholder="Zip Code"
                          onChange={(item: any) => {
                            // reqGeo({ item, currentName });
                            const param = {
                              distance: zipCode?.distance || null,
                              code: item?.value || null,
                            };
                            setZipCode(param);
                            let newParam = {
                              ...filter,
                              page: 1,
                              boolean: isBool,
                              has_resume: hasResume,
                              location: location,
                              zip_code: param,
                              defTags: defTags,
                              job_id: job_id,
                              excluded_job_id: excluded_job_id,
                            };
                            sumbitList(newParam);
                          }}
                          onInputChange={promiseZipCodeOptions}
                          options={itemZipCodes}
                          isClearable
                          isLoading={resGeoSearch.isLoading}
                          formatCreateLabel={(userInput: any) =>
                            `Search "${userInput}"`
                          }
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              width: '100%',
                              border: `1px solid ${
                                !itemZipCodes.filter((option: any) => {
                                  return (
                                    option?.value === zipCode?.code &&
                                    zipCode?.code !== undefined &&
                                    zipCode?.code !== null
                                  );
                                })[0]
                                  ? '#E7EDF4'
                                  : '#6930CA'
                              }`,
                              borderTopLeftRadius: '6px',
                              borderBottomLeftRadius: '6px',
                              borderTopRightRadius: '0px',
                              borderBottomRightRadius: '0px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              color: '#6930CA',
                              '&:hover': { border: '1px solid #6930CA' },
                              '&:focus': {
                                border: '1px solid #6930CA',
                                boxShadow: 'none',
                                color: '#6930CA',
                              },
                            }),
                            dropdownIndicator: (styles: any) => ({
                              display: 'none',
                            }),
                            indicatorSeparator: (styles: any) => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#718096',
                            }),
                            singleValue: (styles: any) => ({
                              ...styles,
                              borderRadius: '6px',
                              color: '#6930CA',
                              fontWeight: 700,
                            }),
                          }}
                          components={{
                            Option: ({ label, innerProps }: any) => (
                              <Box onClick={(e) => innerProps.onClick(e)}>
                                <Flex
                                  sx={{
                                    fontFamily: 'Proxima Nova Regular',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: '12px 24px',
                                    cursor: 'pointer',
                                  }}
                                  _hover={{
                                    backgroundColor: '#F2EFF9',
                                    color: '#2B2D42',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: '14px',
                                    }}
                                  >
                                    {label}
                                  </Box>
                                </Flex>
                              </Box>
                            ),
                          }}
                        />
                      </Box>
                      <Box as="span">
                        <AtsSelect
                          placeholder="Miles"
                          options={milesOptions}
                          value={
                            milesOptions.filter(
                              (option: any) =>
                                option?.value === zipCode?.distance
                            )[0] || null
                          }
                          onChange={(item: any) => {
                            // reqGeo({ item, currentName });
                            const param = {
                              code: zipCode?.code || null,
                              distance: item?.value || null,
                            };
                            setZipCode(param);
                            let newParam = {
                              ...filter,
                              page: 1,
                              boolean: isBool,
                              has_resume: hasResume,
                              zip_code: param,
                              defTags: defTags,
                              job_id: job_id,
                              excluded_job_id: excluded_job_id,
                            };
                            sumbitList(newParam);
                          }}
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              border: `1px solid ${
                                !milesOptions.filter(
                                  (option: any) =>
                                    option?.value === zipCode?.distance
                                )[0]
                                  ? '#E7EDF4'
                                  : '#6930CA'
                              }`,
                              borderLeft:
                                itemZipCodes.filter((option: any) => {
                                  return (
                                    option?.value === zipCode?.code &&
                                    zipCode?.code !== undefined &&
                                    zipCode?.code !== null
                                  );
                                })[0] && 'none',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px',
                              borderTopRightRadius: '6px',
                              borderBottomRightRadius: '6px',
                              minWidth: '100px',
                              color: '#6930CA',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': { border: '1px solid #6930CA' },
                              '&:focus': {
                                border: '1px solid #6930CA',
                                boxShadow: 'none',
                                color: '#6930CA',
                              },
                            }),

                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              padding: '0px',
                              paddingRight: '8px',
                              color: '#718096',
                            }),
                            indicatorSeparator: (styles: any) => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#718096',
                            }),
                            singleValue: (styles: any) => ({
                              ...styles,
                              borderRadius: '6px',
                              color: '#6930CA',
                              fontWeight: 700,
                            }),
                          }}
                          components={{
                            Option: ({ label, innerProps }: any) => (
                              <Box onClick={(e) => innerProps.onClick(e)}>
                                <Flex
                                  sx={{
                                    fontFamily: 'Proxima Nova Regular',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: '12px 24px',
                                    cursor: 'pointer',
                                  }}
                                  _hover={{
                                    backgroundColor: '#F2EFF9',
                                    color: '#2B2D42',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: '14px',
                                    }}
                                  >
                                    {label}
                                  </Box>
                                </Flex>
                              </Box>
                            ),
                          }}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Job Title */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={jobTitle && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={LocationPin} /> */}

                    <Box>Job Title</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      borderRadius: '8px',
                      mb: '8px',
                      p: '0px',
                    }}
                  >
                    <Box>
                      <InputGroup
                        bg="#fff"
                        color="primary.800"
                        sx={{
                          '.chakra-textarea': {
                            fontWeight: 600,
                          },
                          '.chakra-textarea::placeholder': {
                            fontWeight: 'normal',
                          },
                        }}
                      >
                        <InputRightElement cursor="pointer" />
                        <AutoResizeTextarea
                          placeholder="Search Job Title"
                          value={jobTitle}
                          onChange={(e: any) => {
                            setJobTitle(e.target.value);
                            jobTitleChange(e);
                          }}
                          _focus={{
                            boxShadow: 'none',
                            border: '1px solid #E2E8F0',
                          }}
                          sx={{
                            border: `1px solid ${
                              !jobTitle ? '#E7EDF4' : '#6930CA'
                            }`,
                          }}
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLTextAreaElement>
                          ) => {
                            if (e.key === 'Enter') {
                              e.preventDefault(); // Prevent new line
                            }
                          }}
                        />
                      </InputGroup>
                    </Box>
                    <Stack
                      spacing="8px"
                      sx={{
                        mt: '8px',
                      }}
                    >
                      <Box color="default.secondarytext" fontSize="14px">
                        Include:
                      </Box>
                      <CheckboxLayout
                        data={FILTER_INCLUDE}
                        setStatusPayload={setStatusIncludePayload}
                      />
                    </Stack>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Candidate Source Sub Source */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={(filter.source || filter.sub_source) && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={LocationPin} /> */}

                    <Box>Candidate Source</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      borderRadius: '8px',
                      mb: '8px',
                    }}
                  >
                    <Box>
                      <AtsSelect
                        options={candSource}
                        isLoading={resCandSource.isLoading}
                        defaultValue={'values.source_type'}
                        value={
                          (filter.source &&
                            candSource.find(
                              (option) => option.value === filter.source
                            )) ||
                          null
                        }
                        variant="outline"
                        styles={{
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#A0AEC0',
                          }),
                          control: (styles: any) => ({
                            ...styles,
                            cursor: 'pointer',
                            border: `1px solid ${
                              !(
                                filter.source &&
                                candSource.find(
                                  (option) => option.value === filter.source
                                )
                              )
                                ? '#E7EDF4'
                                : '#6930CA'
                            }`,
                            borderRadius: '6px',
                            boxShadow: 'none',
                            '&:hover': { border: '1px solid #E2E8F0' },
                          }),
                          singleValue: (styles: any) => ({
                            ...styles,
                            color: '#6930CA',
                            fontSize: '14px',
                            fontWeight: '600',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#6930CA',
                          }),
                        }}
                        components={{
                          Option: ({ label, innerProps }: any) => (
                            <Box onClick={(e) => innerProps.onClick(e)}>
                              <Flex
                                sx={{
                                  fontFamily: 'Proxima Nova Regular',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  p: '12px 24px',
                                  cursor: 'pointer',
                                }}
                                _hover={{
                                  backgroundColor: '#F2EFF9',
                                  color: '#2B2D42',
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                  }}
                                >
                                  {label}
                                </Box>
                              </Flex>
                            </Box>
                          ),
                        }}
                        onChange={(e: any) => {
                          sourceChange(e);
                          if (e.value === 3 || e.value === 5 || e.value === 6) {
                            // const data = {
                            //   value: authSt?.id,
                            //   label: [
                            //     authSt?.first_name,
                            //     authSt?.last_name,
                            //   ].join(' '),
                            // };

                            setCandSubSource(userSource);
                            setCandSourceVisible(true);
                          } else {
                            subSource(e?.sub);
                            setCandSourceVisible(true);
                            if (e.sub.length === 0) {
                              setCandSourceVisible(false);
                            }
                          }
                        }}
                      />
                      {candSourceVisible && (
                        <>
                          <Flex
                            sx={{
                              gap: '5px',
                              pt: '8px',
                              pb: '4px',
                              color: 'default.primarytext',
                              fontSize: '14px',
                            }}
                            fontWeight="600"
                          >
                            Sub Source
                          </Flex>
                          <Box>
                            <AtsSelect
                              options={candSubSource}
                              value={
                                (filter.sub_source &&
                                  candSubSource.find(
                                    (option) =>
                                      option.value === filter.sub_source
                                  )) ||
                                null
                              }
                              onChange={(e: any) => {
                                subSourceChange(e);
                              }}
                              variant="outline"
                              styles={{
                                placeholder: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  color: '#A0AEC0',
                                }),
                                control: (styles: any) => ({
                                  ...styles,
                                  cursor: 'pointer',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  boxShadow: 'none',
                                  '&:hover': { border: '1px solid #E2E8F0' },
                                }),
                                singleValue: (styles: any) => ({
                                  ...styles,
                                  color: '#6930CA',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }),
                                dropdownIndicator: (styles: any) => ({
                                  ...styles,
                                  color: '#6930CA',
                                }),
                              }}
                              components={{
                                Option: ({ label, innerProps }: any) => (
                                  <Box onClick={(e) => innerProps.onClick(e)}>
                                    <Flex
                                      sx={{
                                        fontFamily: 'Proxima Nova Regular',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: '12px 24px',
                                        cursor: 'pointer',
                                      }}
                                      _hover={{
                                        backgroundColor: '#F2EFF9',
                                        color: '#2B2D42',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          fontWeight: 400,
                                          fontSize: '14px',
                                        }}
                                      >
                                        {label}
                                      </Box>
                                    </Flex>
                                  </Box>
                                ),
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Boolean */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={filter.search && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={LocationPin} /> */}

                    <Box>Boolean Search</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      borderRadius: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        '.chakra-textarea': {
                          fontWeight: 600,
                        },
                        '.chakra-textarea::placeholder': {
                          fontWeight: 'normal',
                        },
                      }}
                    >
                      {/* <Input
              type="search"
              placeholder="Search..."
              value={String(booleanSearch)}
              onChange={(event: any) => {
                // setSearch(event.target.value); //this will temporary  disable the search functionality
                // booleanSearchChange(event);
                setBoleanSearch(event.target.value);
                console.log({ key: event.key });
              }}
              mb="5px"
              bg="#fff"
              color="primary.800"
              fontSize="14px"
              _focus={{
                boxShadow: 'none',
                border: '1px solid #E2E8F0',
              }}
              // onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              //   if (e.key === 'Enter') {
              //     booleanSearchChange(e);
              //     // e.preventDefault(); // Prevent new line
              //   }
              // }}
            /> */}
                      <AutoResizeTextarea
                        placeholder="Search..."
                        value={String(booleanSearch)}
                        onChange={(event: any) => {
                          // setSearch(event.target.value); //this will temporary  disable the search functionality
                          setBoleanSearch(event.target.value);
                        }}
                        mb="5px"
                        bg="#fff"
                        color="primary.800"
                        fontSize="14px"
                        sx={{
                          border: `1px solid ${
                            !booleanSearch ? '#E7EDF4' : '#6930CA'
                          }`,
                        }}
                        _focus={{
                          boxShadow: 'none',
                          border: '1px solid #E2E8F0',
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLTextAreaElement>
                        ) => {
                          if (e.key === 'Enter') {
                            booleanSearchChange(e);
                            e.preventDefault(); // Prevent new line
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Resume updated on */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={(filter.resume_updated_on || hasResume) && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={LocationPin} /> */}
                    {/* <Image src={DocumentScanner} /> */}

                    <Box>Resume Updated On</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      borderRadius: '8px',
                      mb: '8px',
                      '.chakra-select__icon-wrapper': {
                        color: 'primary.800',
                      },
                    }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        pb: '4px',
                        color: 'default.secondarytext',
                        fontSize: '14px',
                      }}
                    ></Flex>
                    <AtsSelect
                      name="company"
                      options={resumeOptions}
                      value={
                        (filter.resume_updated_on &&
                          resumeOptions.find(
                            (option) =>
                              option.label === filter.resume_updated_on
                          )) ||
                        null
                      }
                      variant="outline"
                      onChange={(e: any) => resumeUpdatedOnChange(e)}
                      styles={{
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#A0AEC0',
                        }),
                        control: (styles: any) => ({
                          ...styles,
                          cursor: 'pointer',
                          border: `1px solid ${
                            !(
                              filter.resume_updated_on &&
                              resumeOptions.find(
                                (option) =>
                                  option.label === filter.resume_updated_on
                              )
                            )
                              ? '#E7EDF4'
                              : '#6930CA'
                          }`,
                          borderRadius: '6px',
                          boxShadow: 'none',
                          color: '#6930CA',
                          '&:hover': { border: '1px solid #6930CA' },
                          '&:focus': {
                            border: '1px solid #6930CA',
                            boxShadow: 'none',
                            color: '#6930CA',
                          },
                        }),
                        singleValue: (styles: any) => ({
                          ...styles,
                          borderRadius: '6px',
                          color: '#6930CA',
                          fontWeight: 700,
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#6930CA',
                        }),
                      }}
                      components={{
                        Option: ({ label, innerProps }: any) => (
                          <Box onClick={(e) => innerProps.onClick(e)}>
                            <Flex
                              sx={{
                                fontFamily: 'Proxima Nova Regular',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: '12px 24px',
                                cursor: 'pointer',
                              }}
                              _hover={{
                                backgroundColor: '#F2EFF9',
                                color: '#2B2D42',
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '14px',
                                }}
                              >
                                {label}
                              </Box>
                            </Flex>
                          </Box>
                        ),
                      }}
                    />
                    <Box mt="4px">
                      <Checkbox
                        colorScheme="purple"
                        color="default.secondarytext"
                        onChange={hasResumeChange}
                        isChecked={hasResume}
                      >
                        Is Resume Present
                      </Checkbox>
                    </Box>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Last Activity -- Comment out, since it doesn't have an API yet */}
        {/* <Box
        pb="24px"
        sx={{
          '.chakra-select__icon-wrapper': {
            color: 'primary.800',
          },
        }}
      >
        <Box pb="4px" color="default.secondarytext" fontSize="14px">
          Last Activity
        </Box>
        <AtsSelect
          name="last_activity_time"
          options={lastActivityOptions}
          variant="outline"
        />
      </Box> */}

        {/* Work Status */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: '8px',
            p: '8px',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={filter.work_status && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Image src={Circle} /> */}
                    <Box>Work Status</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      borderRadius: '8px',
                      mb: '8px',
                      '.chakra-select__icon-wrapper': {
                        color: 'primary.800',
                      },
                    }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        pb: '4px',
                        color: 'default.secondarytext',
                        fontSize: '14px',
                      }}
                    ></Flex>
                    <AtsSelect
                      name="work_status"
                      options={workStatusOptions}
                      value={
                        (filter.work_status &&
                          candidate_work_status
                            .filter((item: any) =>
                              filter.work_status?.includes(item.id)
                            )
                            .map((item: any) => ({
                              value: item.id,
                              label: item.status,
                            }))) ||
                        []
                      }
                      variant="outline"
                      onChange={workStatusChange}
                      isMulti
                      styles={{
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#A0AEC0',
                        }),
                        control: (styles: any) => ({
                          ...styles,
                          border: `1px solid ${
                            !(
                              filter.work_status &&
                              candidate_work_status
                                .filter((item: any) =>
                                  filter.work_status?.includes(item.id)
                                )
                                .map((item: any) => ({
                                  value: item.id,
                                  label: item.status,
                                }))
                            )
                              ? '#E7EDF4'
                              : '#6930CA'
                          }`,
                          borderRadius: '6px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': { border: '1px solid #E2E8F0' },
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#6930CA',
                        }),
                        multiValue: (styles: any) => ({
                          ...styles,
                          background: '#F7FAFC',
                          border: '1px solid #E2E8F0',
                          borderRadius: '6px',
                          '&& div:first-of-type': {
                            color: '#6930CA',
                            fontWeight: 600,
                          },
                        }),
                      }}
                      components={{
                        Option: ({ label, innerProps }: any) => (
                          <Box onClick={(e) => innerProps.onClick(e)}>
                            <Flex
                              sx={{
                                fontFamily: 'Proxima Nova Regular',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: '12px 24px',
                                cursor: 'pointer',
                              }}
                              _hover={{
                                backgroundColor: '#F2EFF9',
                                color: '#2B2D42',
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '14px',
                                }}
                              >
                                {label}
                              </Box>
                            </Flex>
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        {/* Tags */}
        <Accordion
          sx={{
            bg: '#FFF',
            mb: count > 0 ? '70px' : '8px',
            p: '8px',
            display: 'block',
            borderRadius: '10px',
          }}
          allowToggle
          defaultIndex={(tagPrivate || filter.tags) && 0}
        >
          <AccordionItem borderColor="transparent">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ color: 'primary.800', pb: '0px' }}
                  _focus={{ border: 'none' }}
                  fontWeight="600"
                  _hover={{ bg: 'none' }}
                >
                  <Flex
                    sx={{
                      gap: '5px',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21.82px',
                      color: '#2B2D42',
                      w: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>Tags</Box>
                    <Box>
                      {isExpanded ? (
                        <Icon
                          as={FaAngleDown}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      ) : (
                        <Icon
                          as={FaAngleUp}
                          w="16px"
                          h="16px"
                          color="#6930CA"
                        />
                      )}
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel pt="8px" pb="0">
                  <Box
                    sx={{
                      bg: '#FFF',
                      mb: '8px',
                      borderRadius: '8px',
                    }}
                  >
                    <RadioGroup value={tagPrivate}>
                      <Stack py={2} spacing={5} direction="row">
                        <Radio
                          color="primary.800"
                          colorScheme="purple"
                          value="1"
                          onChange={(e: any) => setTagPrivate(e.target.value)}
                        >
                          All Tags
                        </Radio>
                        <Radio
                          color="primary.800"
                          colorScheme="purple"
                          value="2"
                          onChange={(e: any) => setTagPrivate(e.target.value)}
                        >
                          Private
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <AtsSelect
                      name="tags"
                      value={
                        (filter.tags &&
                          defOption
                            .filter((option: any) =>
                              filter.tags?.includes(option.value)
                            )
                            .map((item: any) => ({
                              value: item.value,
                              label: item.label,
                            }))) ||
                        []
                      }
                      variant="outline"
                      onChange={tagChange}
                      options={defOption}
                      isMulti
                      isClearable
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          borderRadius: '6px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': { border: '1px solid #E2E8F0' },
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#6930CA',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#A0AEC0',
                        }),
                        multiValue: (styles: any) => ({
                          ...styles,
                          background: '#F7FAFC',
                          border: '1px solid #E2E8F0',
                          borderRadius: '6px',
                          '&& div:first-of-type': {
                            color: '#6930CA',
                            fontWeight: 600,
                          },
                        }),
                      }}
                      components={{
                        Option: ({ label, innerProps }: any) => (
                          <Box onClick={(e) => innerProps.onClick(e)}>
                            <Flex
                              sx={{
                                fontFamily: 'Proxima Nova Regular',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: '12px 24px',
                                cursor: 'pointer',
                              }}
                              _hover={{
                                backgroundColor: '#F2EFF9',
                                color: '#2B2D42',
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '14px',
                                }}
                              >
                                {label}
                              </Box>
                            </Flex>
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>

      {count !== 0 && (
        <Flex
          sx={{
            position: 'fixed',
            bottom: 0,
            bg: '#fff',
            w: '315px',
            borderTop: '1px solid #E6E9EE',
            ml: '-20px',
            p: '16px 24px',
            pl: '30px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '14px', fontWeight: 600, color: '#718096' }}>
            {count} item(s) selected
          </Box>
          <Button variant="solid" size="md" onClick={() => clearFilterPanel()}>
            Clear
          </Button>
        </Flex>
      )}
    </Box>
  );
}
