import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import AtsSelect2 from 'components/app/AtsSelect2';
import Select from 'components/ui/Select/Select';
import { Field, FieldProps } from 'formik';
import { useFetchLeadSourcesQuery } from 'services/common/constants.query';
import { useFetchUsersQuery } from 'services/user/users.query';
import { InitialValueType } from '../type';

export default function Contact() {
  const { data: userList, isLoading: isLoadingUserList } = useFetchUsersQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((data: any) => ({
              label: `${data.first_name} ${data.last_name}`,
              value: data.id,
            }))
          : [],
        ...other,
      }),
    }
  );

  const { data: leadSourceList, isLoading: isLoadingLeadsSource } =
    useFetchLeadSourcesQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data.map((data: any) => ({
                label: data.source,
                value: data.id,
              }))
            : [],
          ...other,
        }),
      }
    );

  return (
    <>
      <Box>
        <Text fontWeight={600} mb="34px">
          Contact
        </Text>
        <Flex gap="32px" mb="34px">
          <Field name="first_name">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.first_name && touched.first_name)}
                isRequired
              >
                <FormLabel>Contact First Name </FormLabel>
                <Field
                  as={Input}
                  id="first_name"
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  variant="outline"
                  value={values.first_name}
                />
                <FormErrorMessage>{String(errors.first_name)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="last_name">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.last_name && touched.last_name)}
                isRequired
              >
                <FormLabel>Contact Last Name </FormLabel>
                <Field
                  as={Input}
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Contact Last Name"
                  variant="outline"
                  value={values.last_name}
                />
                <FormErrorMessage>{String(errors.last_name)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Flex gap="32px" mb="34px">
          <Field name="personal_phone">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(
                  !!errors.personal_phone && touched.personal_phone
                )}
              >
                <FormLabel>Personal Phone</FormLabel>
                <Field
                  as={Input}
                  id="personal_phone"
                  name="personal_phone"
                  type="text"
                  placeholder="Phone Number"
                  variant="outline"
                  value={values.personal_phone}
                />
                <FormErrorMessage>
                  {String(errors.personal_phone)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Flex width="100%" gap="32px">
            <Field name="work_phone">
              {({
                form: { errors, touched, values, handleChange },
              }: FieldProps<InitialValueType>) => (
                <FormControl
                  isInvalid={Boolean(!!errors.work_phone && touched.work_phone)}
                >
                  <FormLabel>Work Phone</FormLabel>
                  <Input
                    id="work_phone"
                    name="work_phone"
                    type="text"
                    placeholder="Work Phone"
                    variant="outline"
                    value={values.work_phone}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>
                    {String(errors.work_phone)}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex width="30%">
              <Field name="extension">
                {({
                  form: { errors, touched, values, setFieldValue },
                }: FieldProps<InitialValueType>) => (
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.work_phone && touched.work_phone
                    )}
                  >
                    <FormLabel>Extension</FormLabel>
                    <Input
                      id="extension"
                      name="extension"
                      type="number"
                      placeholder="Ext"
                      variant="outline"
                      value={values.extension}
                      onChange={(e) => {
                        setFieldValue('extension', String(e.target.value));
                      }}
                    />
                    <FormErrorMessage>
                      {String(errors.extension)}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="32px" mb="34px">
          <Field name="email">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.email && touched.email)}
                isRequired
              >
                <FormLabel>Email </FormLabel>
                <Field
                  as={Input}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  variant="outline"
                  value={values.email}
                />
                <FormErrorMessage>{String(errors.email)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="linkedIn_url">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(
                  !!errors.linkedIn_url && touched.linkedIn_url
                )}
              >
                <FormLabel>LinkedIn Link</FormLabel>
                <Field
                  as={Input}
                  id="linkedIn_url"
                  name="linkedIn_url"
                  type="text"
                  placeholder="LinkedIn Link"
                  variant="outline"
                  value={values.linkedIn_url}
                />
                <FormErrorMessage>
                  {String(errors.linkedIn_url)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Flex gap="32px" mb="34px">
          <Field name="title">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.title && touched.title)}
                isRequired
              >
                <FormLabel>Title </FormLabel>
                <Field
                  as={Input}
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Title"
                  variant="outline"
                  value={values.title}
                />
                <FormErrorMessage>{String(errors.title)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="owner_id">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.owner_id && touched.owner_id)}
                isRequired
              >
                <FormLabel>Contact Owner </FormLabel>
                <Select
                  id="owner_id"
                  name="owner_id"
                  placeholder="Select"
                  value={
                    userList.find(
                      (val) => val.value === Number(values.owner_id)
                    ) || values.owner_id
                  }
                  onChange={(e: any) =>
                    setFieldValue('owner_id', String(e.value))
                  }
                  isLoading={isLoadingUserList}
                  options={userList}
                />
                <FormErrorMessage>{String(errors.owner_id)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Flex gap="32px" mb="34px">
          <Field name="lead_source">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.lead_source && touched.lead_source)}
                isRequired
              >
                <FormLabel>Source </FormLabel>
                <Field
                  id="lead_source"
                  name="lead_source"
                  options={leadSourceList}
                  component={Select}
                  value={values.lead_source}
                  onChange={(e: any) => {
                    setFieldValue(`lead_source`, e);
                  }}
                  isLoading={isLoadingLeadsSource}
                  isError={Boolean(!!errors.lead_source && touched.lead_source)}
                />
                <FormErrorMessage>
                  {errors.lead_source &&
                    touched.lead_source &&
                    String((errors.lead_source as any).label)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>

        {/* <MenuDivider /> */}

        <Box my="34px">
          <FormControl>
            <FormLabel
              fontSize="14px"
              lineHeight="18px"
              color="default.secondarytext"
            >
              Multiple account managers?
            </FormLabel>
          </FormControl>

          <Flex mb="20px">
            <FormLabel
              display="flex"
              gap="10px"
              fontSize="14px"
              lineHeight="18px"
              color="default.secondarytext"
            >
              <Field
                id="isMultipleAccountManager"
                name="isMultipleAccountManager"
                type="radio"
                variant="outline"
                value={'true'}
              />
              Yes
            </FormLabel>
            <FormLabel
              display="flex"
              gap="10px"
              fontSize="14px"
              lineHeight="18px"
              color="default.secondarytext"
            >
              <Field
                id="isMultipleAccountManager"
                name="isMultipleAccountManager"
                type="radio"
                variant="outline"
                value={'false'}
              />
              No
            </FormLabel>
          </Flex>
          <Field name="manager">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.manager && touched.manager)}
                isRequired
              >
                <FormLabel>Account Manager </FormLabel>
                <Field
                  name="manager"
                  isMulti={
                    values.isMultipleAccountManager === 'true' ? true : false
                  }
                  options={userList}
                  component={AtsSelect2}
                  value={values.manager}
                  variant="outline"
                  isLoading={isLoadingUserList}
                />

                <FormErrorMessage>{String(errors.manager)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </Box>
    </>
  );
}
