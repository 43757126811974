import { Box, TabIndicator, TabPanels, Tabs } from '@chakra-ui/react';

import ClientForm from './ClientDetailsPannel';
import JobDetailsForm from './JobDetailsPannel';
import CustomizePitchQuickForm from './QuickJobCustomPitchPannel';
import CustomizePitchFullJobForm from './FullJobCustomPitchPannel';
import JobAdsForm from './JobAdsPannel';
import RecruitingTeam from './RecruitmentTeamPannel';
import { useFormikContext } from 'formik';

import { JobsTabList } from '../TabList';
import { IntialValueType } from '../../utils/validationSchema';
import { useFetchDefaultPitchesQuery } from 'services/common/constants.query';
import FormSkeleton from '../FormSkeleton';
import SentryErrorBoundry from 'components/error-boundary/SentryErrorboundary';

const FULL_JOB_TABLIST = [
  { id: 0, title: 'Client Details' },
  { id: 1, title: 'Job Details' },
  {
    id: 2,
    title: 'Recruiting Team',
  },
  { id: 3, title: 'Job Ads' },
  { id: 4, title: 'Customize Pitch' },
];
const QUICK_JOB_TABLIST = [
  { id: 0, title: 'Client Details' },
  { id: 1, title: 'Job Details' },
  { id: 4, title: 'Customize Pitch' },
];
interface PannelProps {
  tabIndex: number;
  isLoading?: boolean;
  isHideQuickPost?: boolean;
  isContactDisabled?: boolean;
  isClientDisabled?: boolean;
}
// const fulljob
export default function JobsTabPanel({
  tabIndex,
  isLoading,
  isHideQuickPost,
  isClientDisabled,
  isContactDisabled,
}: PannelProps) {
  useFetchDefaultPitchesQuery({});
  const { values } = useFormikContext<IntialValueType>();

  return (
    <Tabs colorScheme="purple" index={tabIndex} h="inherit" px="0px">
      <JobsTabList
        tabList={
          parseInt(values.job_type) === 1 ? FULL_JOB_TABLIST : QUICK_JOB_TABLIST
        }
        tabIndex={tabIndex}
      />
      <TabIndicator color="#6930CA" />
      {isLoading ? (
        <FormSkeleton />
      ) : (
        <TabPanels background="#F9F9FB">
          <RenderPannel tabIndex={0} currentTab={tabIndex}>
            <ClientForm
              isHideQuickPost={Boolean(isHideQuickPost)}
              isClientDisabled={Boolean(isClientDisabled)}
              isContactDisabled={Boolean(isContactDisabled)}
            />
          </RenderPannel>
          {parseInt(values.job_type) === 1 ? (
            <FullJobFormPannels tabIndex={tabIndex} />
          ) : (
            <OuickJobFormPannels tabIndex={tabIndex} />
          )}
        </TabPanels>
      )}
    </Tabs>
  );
}

function FullJobFormPannels({ tabIndex }: Pick<PannelProps, 'tabIndex'>) {
  return (
    <SentryErrorBoundry>
      <RenderPannel tabIndex={1} currentTab={tabIndex}>
        <JobDetailsForm />
      </RenderPannel>
      <RenderPannel tabIndex={2} currentTab={tabIndex}>
        <RecruitingTeam />
      </RenderPannel>
      <RenderPannel tabIndex={3} currentTab={tabIndex}>
        <JobAdsForm />
      </RenderPannel>
      <RenderPannel tabIndex={4} currentTab={tabIndex}>
        <CustomizePitchFullJobForm />
      </RenderPannel>
    </SentryErrorBoundry>
  );
}

function OuickJobFormPannels({ tabIndex }: Pick<PannelProps, 'tabIndex'>) {
  return (
    <SentryErrorBoundry>
      <RenderPannel tabIndex={1} currentTab={tabIndex}>
        <JobDetailsForm />
      </RenderPannel>
      <RenderPannel tabIndex={2} currentTab={tabIndex}>
        <CustomizePitchQuickForm />
      </RenderPannel>
    </SentryErrorBoundry>
  );
}

function RenderPannel({
  tabIndex,
  currentTab,
  children,
}: {
  tabIndex: number;
  currentTab: number;
  children: React.ReactNode;
}) {
  return Boolean(tabIndex === currentTab) ? (
    <Box px={0} pt={2}>
      {children}
    </Box>
  ) : (
    <></>
  );
}
