import Select from 'react-select';

import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import replacePlaceholders from 'utils/textPlaceholders';
import AtsEmailBody from 'components/app/EmailBody';
import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from 'store/template.slice';
import { useParams } from 'react-router';
import { FollowUpTable } from './Layout/Table';
import AtsSelect from 'components/app/AtsSelect';
import EmailPreviewModal from './Layout/Preview';
import {
  listJobs,
  useListFollowUpCandidatesMutation,
  usePostFollowUpCandidatesMutation,
} from 'store/jobs.slice';
import moment from 'moment';
import FollowUpLoading from './Layout/Loading';
import { to_resume_url } from 'utils/encryption';
import { useGetCandidatesMutation } from 'store/candidates.slice';
import follow_up_table from 'constants/follow_up_table';
import { useViewClientMutation } from 'store/client.slice';
import { clientSelectType } from 'types';
import { useUserListMutation } from 'store/user.slice';
import AtsSelectContact from 'components/app/AtsSelectContact';

interface FollowUpModalProps {
  isOpen: any;
  onClose: any;
  isAll: boolean;
  job_id: number;
}

interface templateInt {
  value: number | null;
  label: string | null;
  subject: string | null;
  body: string | null;
  attachments: any | null;
  table: string | null;
  tr: string | null;
  placeholders: any | null;
  subjectmultiple: string | null;
  bodymultiple: string | null;
  table_container: string | null;
}

export const FollowUpModal = ({
  isOpen,
  onClose,
  isAll,
  job_id,
}: FollowUpModalProps) => {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenPreview,
    onClose: onClosePreview,
    onOpen: openPreview,
  } = useDisclosure();

  const { placehoderPair, defaultTemplateList } = useSelector(
    (state: any) => state.template
  );
  const { candidateData } = useSelector((state: any) => state.candidates);

  const { selected_contact } = useSelector((state: any) => state.leads);

  const followupTemplate: any = useMemo(() => {
    const defTemplate =
      defaultTemplateList.filter(
        (template: any) => template?.group_id === 6
      )?.[0] ?? null;
    return defTemplate;
  }, [defaultTemplateList]);

  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqGetClient, resGetClient] = useViewClientMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqList, resList] = useRawListTemplateMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqListFollowUpCandidates, resListFollowUpCandidates] =
    useListFollowUpCandidatesMutation();
  const [reqPostFollowUpCandidates, resPostFollowUpCandidates] =
    usePostFollowUpCandidatesMutation();

  const { candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { jobData, byContactCandidates, selectedCandidates } = useSelector(
    (state: any) => state.jobs
  );
  const { userList } = useSelector((state: any) => state.user);
  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [attachments, setAttachments] = useState([]);
  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [template, setTemplate] = useState([]);
  const [company, setCompany] = useState<clientSelectType | object>({});

  const [defTemplate, setDefTemplate] = useState<templateInt>({
    value: 0,
    label: '',
    subject: followupTemplate?.subject || '',
    body: followupTemplate?.body || '',
    attachments: '',
    table: '',
    tr: '',
    placeholders: '',
    subjectmultiple: '',
    bodymultiple: '',
    table_container: '',
  });
  const [quilNotes, setquilNotes] = useState(followupTemplate?.body || '');
  const [dataCc, setDataCc] = useState([]);
  const [dataBcc, setDataBcc] = useState([]);

  const [bodyData, setBodyData] = useState(quilNotes);

  const [contactOption, setContactOption] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const [contactOptionsEmail, setContactOptionsEmail] = useState([]);

  const [isSubmit, setIsSubmit] = useState(true);
  const [isInterview, setIsInterview] = useState(true);

  const [selectedVersion, setSelectedVersion] = useState(null);

  const [tFuObj, setTFuObj] = useState<any>({});
  const [jobSelect, setJobSelect] = useState(
    byContactCandidates &&
      byContactCandidates.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))
  );
  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          attachments: template?.attachments,
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };
  const jobSelectOptions =
    byContactCandidates &&
    byContactCandidates.map((item: any) => ({
      value: item.id,
      label: item.title,
    }));

  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
    }
  }, [resumes]);

  useEffect(() => {
    if (resPostFollowUpCandidates.isSuccess) {
      onClose();
      setJobSelect([]);
      dispatch(listJobs({ byContactCandidates: [] }));
    }
  }, [resPostFollowUpCandidates.isSuccess]);

  useEffect(() => {
    reqList({ id: 6 });
    const data = {
      //   candidate_id: candidate.id,
      job_id: params?.jobId,
    };
    reqPair(data);
  }, [params.jobId]);

  // useEffect(() => {
  //   if (jobData?.bcc?.cc) {
  //     setDataCc(ccBc(jobData.bcc.cc));
  //   }
  //   if (jobData?.bcc?.bcc) {
  //     setDataBcc(ccBc(jobData.bcc.bcc));
  //   }
  // }, [jobData]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    if (resGetClient.isSuccess) {
      const company = resGetClient.data.data;
      let optionsEmails: any = [];
      if (company !== undefined) {
        setCompany({
          label: company.name,
          value: company.id,
        });
        if (company.leads?.length > 0) {
          company.leads.map(async (item: any) => {
            optionsEmails.push({
              label: [item.first_name, item.last_name]
                .filter(Boolean)
                .join(' '),
              value: item.primary_email,
            });
          });
        }
      }

      // console.log('optinosEmails', optionsEmails);
      // optionsEmails = [...optionsEmails, ...populateUSers()];

      setContactOption(optionsEmails);
      setCcOption(optionsEmails);
      setBccOption(optionsEmails);
      setContactOptionsEmail(optionsEmails);
    }
  }, [resGetClient.isSuccess, selected_contact]);

  const jobTitlePh = (text: string) => {
    const placeholderParam = {
      job_id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          const values = {
            value: item.id || 0,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
            table: item.table || null,
            tr: item.tr || null,
            placeholders: item.placehoder_reference || null,
            subjectmultiple: item.subjectmultiple || item.subject,
            bodymultiple: item.bodymultiple || item.body,
            table_container: item.table_container || null,
          } as templateInt;
          // const values = {
          //   value: item?.id,
          //   label: item?.title,
          //   subject: item?.subject,
          //   body: item?.body,
          //   attachments: item?.attachments,
          // }
          option.push(values);
          // if (values.value == 0) {
          //   setDefTemplate(values);
          //   setTFuObj(values);
          //   if (isAll) {
          //     setquilNotes(item.bodymultiple);
          //   } else {
          //     setquilNotes(jobTitlePh(item.body));
          //   }
          // }
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  // useEffect(() => {
  //   if (isOpen) {
  //     let ccOption = [] as any;
  //     Object.values(userList).map((item: any) => {
  //       ccOption.push({
  //         label: [item.first_name, item.last_name].filter(Boolean).join(' '),
  //         value: item.email,
  //       });
  //     });
  //     setCcBcc(ccOption);
  //   }
  // }, [isOpen]);

  const ccBc = (email: any) => {
    let options: any = [];
    try {
      email.map((email: any) => {
        let label = '';

        if (email.trim() !== '') {
          // console.log('contactOption', contactOption);
          if (contactOption?.length > 0) {
            contactOption.map(async (item: any) => {
              if (item.value === email) {
                label = item.label;
              }
            });
          }
          if (label.trim() === '') {
            Object.values(userList).map((user: any) => {
              // console.log("user.email", user.email);
              if (user.email === email) {
                label = String(
                  [user.first_name, user.last_name].filter(Boolean).join(' ')
                );
              }
              if (user.email === email) {
                label = String(
                  [user.first_name, user.last_name].filter(Boolean).join(' ')
                );
              }
            });
          }
          options.push({
            label: label,
            value: email,
          });
        }
      });
    } catch (e) {
      console.warn('e', e);
    }
    return options;
  };

  const setCc = (jobData: any) => {
    // console.log('jobData?.bcc?.cc', jobData?.bcc?.cc);
    if (jobData?.bcc?.cc) {
      return ccBc(jobData?.bcc?.cc);
    }
    return [];
  };

  const setBcc = (jobData: any) => {
    if (jobData?.bcc?.bcc) {
      return ccBc(jobData?.bcc?.bcc);
    }
    return [];
  };

  const [initialValues, setInitialValue] = useState<any>({
    to: {},
    cc: [] as any,
    bcc: [] as any,
    subject: followupTemplate?.subject || '',
    body: followupTemplate?.body || '',
    // subject: isAll ? defTemplate?.subjectmultiple : defTemplate?.subject,
    // body: isAll ? defTemplate?.bodymultiple : defTemplate?.body,
    primary_resume: true,
  });

  useEffect(() => {
    if (jobData && Object.keys(jobData).length !== 0) {
      const def = {
        ...initialValues,
        to: {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name]
            .filter(Boolean)
            .join(' '),
          value: jobData?.lead?.primary_email,
        },
        cc: setCc(jobData),
        bcc: setBcc(jobData),
      };
      setInitialValue(def);
    }
    // console.log('contactOption', contactOption);
  }, [jobData, contactOption]);

  // console.log('contactOption', contactOption);
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
    })
  );
  const [replaceFollowPlaceholder, setReplaceFollowPlaceholder] =
    useState(false);
  useEffect(() => {
    listCandidates();
  }, [selectedCandidates, replaceFollowPlaceholder, followupTemplate]);

  const placholders = (text: string, values: any) => {
    const work_status = candidate_work_status
      .filter(Boolean)
      .filter((item: any) => Number(item.id) == Number(values.work_status));

    const placeholderParam = {
      ...placehoder,

      candidate_work_status: work_status?.[0]?.status ?? '',
      client_job_title: jobData?.title || '',
      contact_first_name: jobData?.lead?.first_name || '',
      job_id: jobData?.id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  const ccBccValue = (email: any) => {
    let mails = [] as any;
    try {
      email.map((item: any) => {
        mails.push(item.value);
      });
    } catch (e) {
      return mails;
    }
    return mails;
  };

  const onSubmit = (e: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyData, 'text/html');
    const bodyParse = doc.querySelector('body').innerHTML;

    const data = {
      ...e,
      to: e.to.value,
      cc: ccBccValue(e.cc),
      bcc: ccBccValue(e.bcc),
      body: bodyParse,
      subject: placholders(e.subject, e),
      candidates: selectedCandidates.map(
        ({ candidate_id, status_id, job_id }: any) => ({
          candidate_id,
          status_id,
          job_id,
        })
      ),
    };
    // console.log('data', data);
    reqPostFollowUpCandidates({
      id: jobData?.lead_id || 0, // TODO: Which id to be used
      data,
    });
  };

  const toResumeUrl = (id: any, name: any) => {
    const url = to_resume_url(id);
    return `<a href="${url}" target="_blank">${name}</a>`;
  };

  const loadConstant = () => {
    if (jobData.client?.id) {
      const client_id = jobData.client?.id;
      reqGetClient({ id: client_id });
    }
    reqUsers({});
  };

  useEffect(() => {
    if (isOpen) {
      loadConstant();
    }
  }, [isOpen, selected_contact]);

  // OLD IMPLEMENTATION
  // const listCandidates = async () => {
  //   // tFuObj;
  //   //follow-up-container for multiple
  //   const tr = tFuObj.tr;
  //   const htmlString = quilNotes;
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, 'text/html');

  //   if (!isAll) {
  //     const element = doc.getElementById(`fj-${jobData?.id}`);
  //     let html: string = '';
  //     if (selectedCandidates.length > 0) {
  //       await Promise.all(
  //         selectedCandidates.map((item: any) => {
  //           // console.log('item', item);
  //           const placeholders = {
  //             job_id: jobData?.id,
  //             candidate_full_name: toResumeUrl(item.candidate_id, item.name),
  //             status: item.status,
  //             submitted_interview_date: moment
  //               .utc(item.date)
  //               .format('MM/DD/YYYY'),
  //           } as any;
  //           // console.log('placeholders', placeholders);
  //           try {
  //             html += replacePlaceholders(tr, placeholders);
  //           } catch (e) {
  //             console.warn('e', e);
  //           }
  //         })
  //       );
  //     } else {
  //       html = tr;
  //     }

  //     if (element) {
  //       element.innerHTML = html;
  //       const serializer = new XMLSerializer();
  //       const newHtmlString = serializer.serializeToString(doc);
  //       setquilNotes(newHtmlString);
  //       // console.log("newHtmlString")
  //     }
  //   } else {
  //     const container = doc.getElementById(`follow-up-container`);
  //     const candidates = [...selectedCandidates];
  //     let grouped = [] as any;
  //     if (candidates?.length > 0) {
  //       candidates?.map((item: any) => {
  //         if (item) {
  //           if (!grouped[item?.job_id]) {
  //             grouped[item?.job_id] = [] as any;
  //           }
  //           grouped[item?.job_id].push(item);
  //         }
  //       });

  //       if (grouped.length > 0) {
  //         //table_content
  //         //table_list
  //         let content = ``;
  //         grouped.map((groupings: any) => {
  //           let tr_content = ``;
  //           let job_title = '';
  //           groupings.map((item: any) => {
  //             job_title = item?.job_title;
  //             const placeholders = {
  //               job_id: item?.job_id,
  //               candidate_full_name: toResumeUrl(item.candidate_id, item.name),
  //               status: item.status,
  //               submitted_interview_date: moment
  //                 .utc(item.date)
  //                 .format('MM/DD/YYYY'),
  //             } as any;
  //             try {
  //               tr_content += replacePlaceholders(tr, placeholders);
  //             } catch (e) { }
  //           });
  //           if (tr_content != '') {
  //             const ph = {
  //               tbody: tr_content,
  //             };
  //             const table_content = replacePlaceholders(tFuObj.table, ph);
  //             const container_ph = {
  //               table_content,
  //               client_job_title: job_title,
  //             };
  //             console.log("container_ph", container_ph)
  //             console.log("tFuObj", tFuObj)
  //             content += replacePlaceholders(
  //               tFuObj.table_container,
  //               container_ph
  //             );
  //           }
  //         });

  //         if (container) {
  //           container.innerHTML = content;
  //           const serializer = new XMLSerializer();
  //           const newHtmlString = serializer.serializeToString(doc);
  //           setquilNotes(newHtmlString);
  //         }
  //       }
  //     }
  //   }
  // };

  const replaceFollowUpPlaceholder = (value: string) => {
    const textInit = value;
    const follow_up_table_body = isAll
      ? follow_up_table.bodymultiple
      : jobTitlePh(follow_up_table.body);

    const regex = new RegExp(`{{follow_up_table}}`, 'g');

    console.log('follow_up_table_body', follow_up_table_body);
    const text = textInit.replace(regex, follow_up_table_body);
    setBodyData(text);
    if (regex.test(textInit)) {
      setReplaceFollowPlaceholder(true);
    }
  };

  useMemo(() => {
    if (followupTemplate !== null) {
      setInitialValue({
        ...initialValues,
        subject: followupTemplate?.subject || '',
        body: followupTemplate?.body || '',
      });
      setBodyData(followupTemplate?.body || '');
      setquilNotes(followupTemplate?.body || '');
      replaceFollowUpPlaceholder(followupTemplate?.body);
    }
  }, [followupTemplate]);

  const listCandidates = async () => {
    // tFuObj;
    //follow-up-container for multiple
    const tr = follow_up_table.tr;
    const htmlString = bodyData;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    setReplaceFollowPlaceholder(false);

    if (!isAll) {
      const elements = Array.from(
        doc.querySelectorAll(`[id^="fj-${jobData?.id}"]`)
      ); // Select all elements with IDs starting with `fj-${jobData?.id}`
      let html: string = '';
      if (selectedCandidates.length > 0) {
        await Promise.all(
          selectedCandidates.map((item: any) => {
            const placeholders = {
              job_id: jobData?.id,
              candidate_full_name: toResumeUrl(item.candidate_id, item.name),
              status: item.status,
              submitted_interview_date: moment
                .utc(item.date)
                .format('MM/DD/YYYY'),
            } as any;
            try {
              html += replacePlaceholders(tr, placeholders);
            } catch (e) {
              console.warn('e', e);
            }
          })
        );
      } else {
        html = tr;
      }

      elements.forEach((element) => {
        element.innerHTML = html;
      });

      const serializer = new XMLSerializer();
      const newHtmlString = serializer.serializeToString(doc);
      setBodyData(newHtmlString);
    } else {
      const container = doc.getElementById(`follow-up-container`);
      const candidates = [...selectedCandidates];
      let grouped = [] as any;
      if (candidates?.length > 0) {
        candidates?.map((item: any) => {
          if (item) {
            if (!grouped[item?.job_id]) {
              grouped[item?.job_id] = [] as any;
            }
            grouped[item?.job_id].push(item);
          }
        });

        if (grouped.length > 0) {
          //table_content
          //table_list
          let content = ``;
          grouped.map((groupings: any) => {
            let tr_content = ``;
            let job_title = '';
            groupings.map((item: any) => {
              job_title = item?.job_title;
              const placeholders = {
                job_id: item?.job_id,
                candidate_full_name: toResumeUrl(item.candidate_id, item.name),
                status: item.status,
                submitted_interview_date: moment
                  .utc(item.date)
                  .format('MM/DD/YYYY'),
              } as any;
              try {
                tr_content += replacePlaceholders(tr, placeholders);
              } catch (e) {}
            });
            if (tr_content != '') {
              const ph = {
                tbody: tr_content,
              };
              const table_content = replacePlaceholders(
                follow_up_table.table,
                ph
              );
              const container_ph = {
                table_content,
                client_job_title: job_title,
              };
              content += replacePlaceholders(
                follow_up_table.table_container,
                container_ph
              );
            }
          });

          if (container) {
            container.innerHTML = content;
            const serializer = new XMLSerializer();
            const newHtmlString = serializer.serializeToString(doc);
            setBodyData(newHtmlString);
          }
        }
      }
    }
  };

  const populateUsers = () => {
    let emails = [] as any;

    Object.values(userList).map((item: any) => {
      emails.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });
    return emails;
  };

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUsers()];
      setCcOption(options);
    } else {
      setCcOption(contactOptionsEmail);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUsers()];
      setBccOption(options);
    } else {
      setBccOption(contactOptionsEmail);
    }
  };

  const jobSelectChange = (e: any) => {
    setJobSelect(e);
  };

  const handlePreview = () => {
    replaceFollowUpPlaceholder(bodyData);
    reqGetCandidate({ id: selectedCandidates[0]?.candidate_id });
    openPreview();
  };

  useEffect(() => {
    reqListFollowUpCandidates({
      job_id: isAll
        ? jobSelect.map((val: any) => Number(val.value))
        : [jobData.id],
      status_id: [isSubmit && 1, isInterview && 3],
    });
  }, [isInterview, isSubmit, jobSelect, jobData]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
        setJobSelect([]);
        dispatch(listJobs({ byContactCandidates: [] }));
      }}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          <Flex justifyContent="space-between">
            <Flex sx={{ gap: '16px', alignItems: 'center' }}>
              <Box mr="8px">
                {isAll ? 'Follow-up for All Jobs' : 'Follow-up'}
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  defaultChecked={isSubmit}
                  name="primary"
                  id="primary"
                  onChange={(e) => {
                    setIsSubmit(e.target.checked);
                  }}
                >
                  Submit
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  defaultChecked={isInterview}
                  name="primary"
                  id="primary"
                  onChange={(e) => {
                    setIsInterview(e.target.checked);
                  }}
                >
                  Interview
                </Checkbox>
              </Box>
            </Flex>
            {isAll ? (
              <Box
                sx={{
                  mr: '30px',
                  '.chakra-select__icon-wrapper': {
                    color: 'primary.800',
                  },
                }}
              >
                <AtsSelect
                  name="job_select"
                  options={jobSelectOptions}
                  value={jobSelect || []}
                  variant="outline"
                  onChange={jobSelectChange}
                  isMulti
                  styles={{
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#A0AEC0',
                    }),
                    control: (styles: any) => ({
                      ...styles,
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minWidth: '400px',
                      maxWidth: '400px',
                      '&:hover': { border: '1px solid #E2E8F0' },
                    }),
                    valueContainer: (styles: any) => ({
                      ...styles,
                      overflowY: 'scroll',
                      maxHeight: '70px',
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#6930CA',
                      zIndex: 9999999,
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#6930CA',
                        fontWeight: 600,
                      },
                    }),
                  }}
                  components={{
                    Option: ({ label, innerProps }: any) => (
                      <Box onClick={(e) => innerProps.onClick(e)}>
                        <Flex
                          sx={{
                            fontFamily: 'NunitoSans Regular',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '12px 24px',
                            cursor: 'pointer',
                          }}
                          _hover={{
                            backgroundColor: 'rgba(239, 229, 255, 0.4)',
                            color: 'primary.800',
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                            }}
                          >
                            {label}
                          </Box>
                        </Flex>
                      </Box>
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box
                id={`tagTooltip`}
                color="primary.600"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={500}
                height="fit-content"
                mr="30px"
              >
                <Link
                  href={`/jobs/${jobData.id}/details`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {jobData.title}
                </Link>
              </Box>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          sx={{
            margin: isAll && '20px 0',
            top: isAll ? 0 : '13px',
            right: '20px',
            color: 'default.secondarytext',
          }}
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                {/* <AlertDescription>{errMsg}</AlertDescription> */}
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          overflow="hidden"
          height="100vh"
        >
          {resListFollowUpCandidates.isLoading || resList.isLoading ? (
            <FollowUpLoading />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />

                  <Flex>
                    <Box
                      width="60%"
                      height="90vh"
                      py={2}
                      borderRight="1px solid"
                      borderColor="default.borderlight"
                      px={3}
                      pr={0}
                    >
                      <FollowUpTable />
                    </Box>

                    <Box
                      width="40%"
                      p="32px"
                      sx={{ overflowY: 'scroll', height: '90vh' }}
                    >
                      <>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.to && touched.to)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              To
                            </FormLabel>
                            {/* <Input
                              id="to"
                              name="to"
                              type="email"
                              variant="outline"
                              value={values.to}
                              onChange={handleChange}
                            /> */}
                            <Field
                              id="to"
                              name="to"
                              component={AtsSelectContact}
                              // defaultValue={values.contact}
                              value={values.to}
                              // onChange={() => {}}
                              client={company}
                              // onChange={(event: any) =>
                              //   setFieldValue('cc', event)
                              // }
                              options={contactOption}
                              placeholder="Select A Contact"
                              defaultValue={values.to}
                              isSaveInput={true}
                            />
                            <FormErrorMessage>
                              {String(errors.to)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.cc && touched.cc)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Cc
                            </FormLabel>
                            {/* <AtsCraetableSelect
                              id="cc"
                              name="cc"
                              onChange={(event: any) =>
                                setFieldValue('cc', event)
                              }
                              options={ccOption}
                              isMulti={true}
                              placeholder="cc"
                              defaultValue={values.cc}
                              onInputChange={onCcInputChange}
                            /> */}
                            <Field
                              id="cc"
                              name="cc"
                              component={AtsSelectContact}
                              // defaultValue={values.contact}
                              value={values.cc}
                              // onChange={() => {}}
                              client={company}
                              // onChange={(event: any) =>
                              //   setFieldValue('cc', event)
                              // }
                              options={ccOption}
                              isMulti={true}
                              placeholder="cc"
                              defaultValue={values.cc}
                              onInputChange={onCcInputChange}
                              isSaveInput={true}
                            />

                            <FormErrorMessage>
                              {String(errors.cc)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Bcc
                            </FormLabel>
                            {/* <AtsCraetableSelect
                              id="bcc"
                              name="bcc"
                              onChange={(event: any) => {
                                setFieldValue('bcc', event);
                              }}
                              options={bccOption}
                              isMulti={true}
                              placeholder="bcc"
                              defaultValue={values.bcc}
                              onInputChange={onBccInputchange}
                            /> */}
                            <Field
                              id="bcc"
                              name="bcc"
                              component={AtsSelectContact}
                              // defaultValue={values.contact}
                              value={values.bcc}
                              // onChange={() => {}}
                              client={company}
                              // onChange={(event: any) =>
                              //   setFieldValue('cc', event)
                              // }
                              options={bccOption}
                              isMulti={true}
                              placeholder="bcc"
                              defaultValue={values.bcc}
                              onInputChange={onBccInputchange}
                              isSaveInput={true}
                            />
                            <FormErrorMessage>
                              {String(errors.cc)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl>
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Template
                            </FormLabel>
                            <Select
                              placeholder="Select"
                              isMulti={false}
                              options={template}
                              id="template"
                              // defaultValue={defTemplate}
                              value={populateTemplate(followupTemplate)}
                              onChange={(e: any) => {
                                setTFuObj(e);
                                // if (isAll) {
                                //   setquilNotes(e.bodymultiple);
                                //   setFieldValue('body', e.bodymultiple);
                                //   setFieldValue('subject', e.subjectmultiple);
                                // } else {
                                setquilNotes(e.body);
                                setBodyData(e.body);
                                setFieldValue('body', e.body);
                                setFieldValue('subject', e.subject);
                                // }
                                setAttachments(e.attachments);
                              }}
                            />
                          </FormControl>
                        </Box>
                        <Box mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.subject && touched.subject
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Subject{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="subject"
                              name="subject"
                              type="text"
                              variant="outline"
                              placeholder="Subject"
                              value={values.subject}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.subject)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="250px">
                          <FormControl
                            isInvalid={Boolean(!!errors.body && touched.body)}
                          >
                            <Text mb="10px" fontWeight={700}>
                              Body
                            </Text>
                            <AtsEmailBody
                              id="notes"
                              value={quilNotes}
                              height={250}
                              onChange={(e: any) => {
                                setquilNotes(e);
                                setBodyData(e);
                                setFieldValue('body', e);
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.body)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      </>
                    </Box>
                  </Flex>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Flex sx={{ gap: '10px', alignItems: 'center' }}>
                      <Box>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          defaultChecked={values.primary_resume}
                          name="primary_resume"
                          id="primary_resume"
                          onChange={handleChange}
                        >
                          Attach Primary Resume
                        </Checkbox>
                      </Box>
                      <Button onClick={() => handlePreview()}>
                        <Flex gap="10px" dir="column" alignItems="center">
                          <FAIcon iconName="eye" />
                          <Box>Preview</Box>
                        </Flex>
                      </Button>
                      <Button
                        variant="solid"
                        type="submit"
                        disabled={
                          isOpenAlert || resPostFollowUpCandidates.isLoading
                        }
                        loading={resPostFollowUpCandidates.isLoading}
                        onClick={() => {
                          replaceFollowUpPlaceholder(bodyData);
                          handleSubmit();
                        }}
                        //   loading={resSubmit.isLoading}
                      >
                        Send Follow-up
                      </Button>
                    </Flex>

                    {isOpenPreview && (
                      <EmailPreviewModal
                        data={{
                          ...values,
                          body: bodyData,
                          attachments,
                          resume: `${selectedVersion?.original_file_name}.${
                            selectedVersion?.file_name.split('.').splice(-1)[0]
                          }`,
                        }}
                        // selectedCandidate={selectedCandidates[0]}
                        isOpen={isOpenPreview}
                        onClose={onClosePreview}
                        onSubmit={handleSubmit}
                        disabled={
                          isOpenAlert || resPostFollowUpCandidates.isLoading
                        }
                        isLoading={resPostFollowUpCandidates.isLoading}
                        isFollowUp
                      />
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          )}
          {/* <FollowUpLoading /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FollowUpModal;
