import { Box, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import * as Sentry from '@sentry/react';

export default function RouterErrorBoundary() {
  const error = useRouteError() as Error;

  useEffect(() => {
    //logging in sentry
    Sentry.captureException(error, { level: 'fatal' });
  }, [error]);

  if (!isRouteErrorResponse(error) || error.status !== 401) {
    throw error;
  }

  return (
    <Stack
      alignItems="center"
      pt={10}
      gap={3}
      w="80%"
      mx="auto"
      height="100%"
      minH={'500px'}
    >
      <Text fontSize="xl" fontWeight="700" textAlign="center">
        Something went wrong
      </Text>
      <Text fontSize="medium" textAlign={'center'}>
        {error.message || JSON.stringify(error)}
      </Text>
    </Stack>
  );
}
