import {
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import FilterList from 'components/app/AtsSelectCandidateView/filterlist';
import useFilter from 'modules/Candidates/components/tables/hooks/useFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { setGroupFilter } from 'store/candidates.slice';
import FilterSelect from './FilterSelect';

const TABS = [
  { id: 0, title: 'search', display: 'Search' },
  { id: 1, title: 'views', display: 'Views' },
];
export default function ButtonTabs({
  children,
  onTabSwitch,
}: {
  children: React.ReactNode;
  onTabSwitch: () => void;
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const tab = useMemo(() => {
    return TABS.find((tab) => location.pathname.includes(tab.title)).id;
  }, [location.pathname]);
  const activeFilter = useSelector(
    (state: any) => state?.candidates?.groupFilter
  );
  function handleTabChange(index: number) {
    onTabSwitch();
    const tab = TABS[index];
    navigate(`/candidates/${tab.title}`);
  }
  const filterPinChange = async (e: any) => {
    dispatch(setGroupFilter(e?.filter));
    localStorage.setItem('selectedPinFilter', JSON.stringify(e?.filter));
  };

  useEffect(() => {
    if (tab === 1) {
      const item = localStorage.getItem('selectedPinFilter');
      const e = JSON.parse(item);
      if (e) {
        dispatch(setGroupFilter(e));
      }
    } else {
      dispatch(setGroupFilter({}));
    }
  }, [tab]);

  return (
    <Tabs index={tab} isFitted variant="unstyled" onChange={handleTabChange}>
      <TabList
        bg="white"
        sx={{
          px: '8px',
          py: '16px',
          borderRadius: '10px',
          position: 'sticky',
          top: 0,
          zIndex: 20,
          boxShadow: '-2px 9px 11px -8px rgba(0,0,0,0.03)',
        }}
      >
        <Flex
          w="100%"
          bg="#FAF6FF"
          borderRadius="10px"
          p="1"
          color="default.gray.1000"
        >
          {TABS.map((tab) => (
            <Tab
              _focus={{ border: 'none' }}
              _selected={{
                color: 'primary.800',
                bg: 'white',
                borderRadius: '10px',
                fontWeight: 700,
              }}
              w="100%"
            >
              {tab.display}
            </Tab>
          ))}
        </Flex>
      </TabList>
      <TabPanels>
        <TabPanel p="0" mt="10px">
          <Stack gap="8px">{children}</Stack>
        </TabPanel>
        <TabPanel p="0" mt="10px">
          <Stack gap="8px">
            {tab === 1 ? (
              <Box
                sx={{
                  bg: '#FFF',
                  borderRadius: '8px',
                  p: '16px',
                }}
              >
                <Box>
                  <FilterSelect />
                </Box>
                <FilterList
                  active={activeFilter?.value}
                  setFilterActive={(e) => {
                    //   dispatch(setPinnedFilter(e))
                  }}
                  onChange={(e) => {
                    filterPinChange(e);
                  }}
                />
              </Box>
            ) : null}
            {children}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
