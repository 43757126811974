import {
  Box,
  Flex,
  Text,
  Icon,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import RenderIf from 'components/RenderIf';
import ModalLoader from 'components/ui/Loaders/ModalLoader';
import useFilter from 'modules/Candidates/components/tables/hooks/useFilter';
import filterSelector from 'modules/Candidates/components/tables/utils/filterSelector';
import { CandidateTableColumnsType } from 'modules/Candidates/types';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import { FaPencilAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import {
  useFetchCandidateFiltersListQuery,
  useFetchCandidatePinnedFilterListQuery,
} from 'services/candidates/candidate.query';
import {
  usePinCandidateFilterMutation,
  useUnPinCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { resetFilter, setGroupFilter } from 'store/candidates.slice';
import {
  useGetCandidateFilterColumnsMutation,
  useGetFilterOperatorsMutation,
} from 'store/constant.slice';

const CandidateViewModal = lazy(
  () => import('components/app/Candidates/Modals/CandidateView')
);

interface OptionType {
  label: string;
  value: number;
  isPinned: boolean;
  data: {
    title: string;
    module: {
      criteria: string;
      condition: string;
      query: {
        value: number;
        label: string;
      }[];
    }[];
    share: string;
    user: { label: string; value: number }[];
    operators: { id: number; operator: string }[];
    group_by_job: boolean;
    columns: Record<CandidateTableColumnsType, number>;
    tableCol?: {
      order: string[];
      visibility: Record<string, boolean>;
    };
  } | null;
}

export default function FilterSelect() {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const filter = useFilter({});
  const [editFilterData, setEditFilterData] = useState<OptionType | null>(null);
  const { data: options, isLoading } = useFetchCandidateFiltersListQuery(
    filter,
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: data
          ? data
              .map((filter) => filterSelector(filter))
              .concat([
                {
                  label: 'Create New View',
                  value: 0,
                  isPinned: false,
                  data: null,
                  group_by_job: false,
                },
              ])
          : [],
      }),
    }
  );

  const [reqCandidateFilterColumn] = useGetCandidateFilterColumnsMutation();
  const [reqFilterOperators] = useGetFilterOperatorsMutation();
  const [isChangingPin, setIsChangingPin] = useState(null);
  const [reqPin] = usePinCandidateFilterMutation();
  const [reqUnpin] = useUnPinCandidateFilterMutation();
  const filterValue = useSelector(
    (state: any) => state?.candidates?.groupFilter
  );
  function handleChange(e: OptionType) {
    dispatch(setGroupFilter(e));
  }
  useEffect(() => {
    if (isOpen) {
      //fetched for candidate view modal
      reqCandidateFilterColumn({});
      reqFilterOperators({});
    }
  }, [isOpen]);

  return (
    <>
      <ReactSelect
        value={filterValue}
        onChange={handleChange}
        options={options}
        isLoading={isLoading}
        styles={{
          control: (styles) => ({
            ...styles,
            background: '#a475f4',
            border: '1px solid #E2E8F0',
            boxShadow: 'none',
            '&:hover': { border: '1px solid #E2E8F0' },
          }),
          singleValue: (styles) => ({
            ...styles,
            color: 'white',
            fontSize: '14px',
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            color: 'white',
          }),
          placeholder: (styles) => ({
            ...styles,
            color: 'white',
          }),
        }}
        components={{
          Option: ({ label, data, innerProps, isSelected }) => {
            return label === 'Create New View' ? (
              <CreateNewViewOption
                onClick={() => {
                  onOpen();
                  dispatch(resetFilter());
                }}
              />
            ) : (
              <Option
                label={label}
                isActive={isSelected}
                onClick={innerProps.onClick}
                isPinned={data.isPinned}
                isPinChanging={isChangingPin === data.value}
                onEdit={() => {
                  setEditFilterData(data);
                  onOpen();
                }}
                onPinChange={() => {
                  setIsChangingPin(data.value);
                  (data.isPinned
                    ? reqUnpin({ id: data.value })
                    : reqPin({ id: data.value })
                  ).finally(() => setIsChangingPin(null));
                }}
              />
            );
          },
        }}
      />

      <RenderIf condition={isOpen}>
        <Suspense fallback={<ModalLoader />}>
          <CandidateViewModal
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setEditFilterData(null);
            }}
            id={editFilterData?.value}
            candidateViewData={editFilterData?.data}
            isEdit={!!editFilterData?.label}
          />
        </Suspense>
      </RenderIf>
    </>
  );
}

function Option({
  label,
  isActive,
  isPinChanging,
  onClick,
  onEdit,
  isPinned,
  onPinChange,
}: {
  label: string;
  isActive: boolean;
  isPinned: boolean;
  isPinChanging: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onEdit: (e: React.MouseEvent<HTMLDivElement>) => void;
  onPinChange: () => void;
}) {
  return (
    <Flex
      justifyContent={'space-between'}
      gap="15px"
      alignItems="center"
      sx={{
        fontFamily: 'NunitoSans Regular',
        p: '12px 24px',
        cursor: 'pointer',
        backgroundColor: isActive ? '#a475f4' : '#fff',
        color: isActive && '#fff',
      }}
      _hover={{
        backgroundColor: 'rgba(239, 229, 255, 0.4)',
        color: '#1B202B',
      }}
      onClick={onClick}
    >
      <Text
        sx={{
          maxWidth: '130px',
          fontSize: '12px',
          fontWeight: 600,
        }}
        isTruncated
      >
        {label}
      </Text>
      <Flex alignItems="center" gap="15px">
        {isPinChanging ? (
          <Box
            sx={{ cursor: 'pointer', color: 'primary.800' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Spinner size="sm" speed="0.65s" />
          </Box>
        ) : (
          <Box
            sx={{
              cursor: 'pointer',
              color: isPinned ? (isActive ? '#fff' : 'primary.800') : '',
              fontSize: '12px',
            }}
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              onPinChange();
            }}
          >
            {isPinned ? <Icon as={BsPinAngleFill} /> : <Icon as={BsPinAngle} />}
          </Box>
        )}
        <Box
          sx={{
            cursor: 'pointer',
            // color: isActive ? '#fff' : 'gray.600',
            fontSize: '12px',
          }}
          className="icon"
          onClick={onEdit}
        >
          <Icon as={FaPencilAlt} fontSize="12px" />
        </Box>
      </Flex>
    </Flex>
  );
}
function CreateNewViewOption({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <>
      <Box
        sx={{
          fontFamily: 'NunitoSans Regular',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          w: '100%',
          position: 'sticky',
          bottom: -1,
          bgColor: '#fff',
        }}
        m="0"
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            fontSize: '12px',
            p: '12px 24px',
            fontWeight: 600,
            color: 'primary.800',
            margin: '0 auto',
            backgroundColor: 'rgba(239, 229, 255, 0.4)',
          }}
        >
          <FAIcon iconName="plus" />
          <Text sx={{ textAlign: 'center' }}>Create New View</Text>
        </Flex>
      </Box>
    </>
  );
}
