import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import App from 'routes/App';
import theme from './theme/theme';
import { store } from './store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './assets/css/App.css';

const env = process.env.REACT_APP_ENV;
const server = process.env.REACT_APP_URL;
const sentry = process.env.REACT_APP_SENTRY;

const convertUrlToRegexPattern = (url: any) => {
  // console.log({ url });
  // return url;
  // Extract the protocol and domain from the URL
  const { protocol, hostname } = new URL(url);

  // Escape special characters for regex
  const escapedProtocol = protocol.replace(/:/g, '\\:');
  const escapedHostname = hostname.replace(/\./g, '\\.');

  // Construct the regex pattern
  const regexPattern = `/^${escapedProtocol}//${escapedHostname}`;

  return regexPattern;
};
convertUrlToRegexPattern(server);
if (sentry) {
  Sentry.init({
    dsn: sentry,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [env, convertUrlToRegexPattern(server)],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </ChakraProvider>
  // document.getElementById("root")
);
