import { DEFAULT_VALUES } from '../constants/defaults';
import { IntialValueType } from './validationSchema';

export function extractProtocolAndRest(url: string) {
  if (!url) return { protocol: '', rest: '' };
  const regex = /^(https?:\/\/)(.+)$/; // Regex to separate protocol and the rest of the URL
  const match = url.match(regex);

  if (match) {
    const protocol = match[1]; // The protocol (http:// or https://)
    const rest = match[2]; // The rest of the URL (domain + path)
    return { protocol, rest };
  } else {
    return { protocol: '', rest: '' };
  }
}

export function placeholderUrl(url: string, children: string) {
  return `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${children}</a>`;
}

export function extractPayloadData(
  values: IntialValueType,
  isDraft: boolean
): Record<string, any> {
  const defaults = {
    //client-details
    company: values.client,
    contact: { label: values.contact?.label, value: values.contact?.value },
    location: values.location.value,
    spec_id: values.industry.value,
    cc: values?.cc?.map((cc: { value: string }) => cc.value),
    bcc: values?.bcc?.map((cc: { value: string }) => cc.value),

    //job-details
    am_id: values.account_manager.value,
    primary_recruiter: values.primary_recruiter.value,
    title: values.client_job_title,
    experience_level: `${values.experience_level.value}`,
    skills: values.skills.map((skill: { label: string }) => skill.label),
    notes: values.client_note,

    //custom pitch
    customize_pitch: {
      email_content: values.body,
      email_subject: values.subject,
    },

    job_status_id: isDraft ? 5 : 1,
    status: 1,
    lead_id: 1,
  };

  //Quick job
  if (values.job_type === '2') {
    return defaults;
  }
  return {
    ...defaults,
    job_type_id: parseInt(values.job_type),
    //job-detail
    opening: values?.no_of_opening ?? 1,
    education_id: `${values?.education?.value}`,
    work_type_id: `${values?.work_type?.value}`,
    client_job_link: `${values?.job_link_type?.value}${values?.job_link_domain}`,
    employment_type_id: values?.employment_type?.value ?? '',
    compensation_id: values?.compensation_type?.value ?? '',
    salary_range_start: parseFloat(
      values?.salary_range_start?.replace(/,/g, '')
    ),
    salary_range_end: parseFloat(values?.salary_range_end?.replace(/,/g, '')),
    description: values?.client_job_description ?? '',

    //recruiter team
    recruiters: values.recruiters.map((recruiter) => ({
      name: `${recruiter.recruiter.value}`,
      split_percentage: recruiter.split,
      post_new_job_ads: recruiter.post_new_job_ads,
      no_submittal_review: recruiter.review === 'no_submittal_review',
      review_send_as_client_owner:
        recruiter.review === 'review_send_as_client_owner',
      review_send_as_client_recruiter:
        recruiter.review === 'review_send_as_client_recruiter',
      interview_send_as_client_owner:
        recruiter.interview === 'interview_send_as_client_owner',
      interview_send_as_recruiter:
        recruiter.interview === 'interview_send_as_recruiter',
    })),

    //job ads
    job_ads: [
      {
        title: values?.posting_title,
        state: values?.state,
        city: values?.city,
        country: values?.country?.label,
        zip_code: values?.zip_code,
        description: values?.job_description,
        career_page_publish: values?.career_page,
        show_profile: values?.show_profile,
        free_job_board_publish: values?.free_job,
        paid_job_board_publish: values?.paid_job,
        active: 1,
        worktype_id: `${values?.work_type?.value}`,
      },
    ],

    //Custom pitch
    campaign: values?.campaign_status?.value,
    questions: [] as any,
  };
}

type ValueType = {
  label: string;
  value: number;
};
interface GetDefaultInitialValueProps {
  defaultPitch?: {
    subject: string;
    content: string;
  };
  clientValue?: ValueType | string;
  contactValue?: ValueType | string;
  industry?: ValueType | string;
  account_manager?: ValueType | string;
  primary_recruiter?: ValueType | string;
  contactList?: Array<ValueType>;
  emailList?: Array<{ label: string; value: string }>;
  managerList?: Array<ValueType>;
  company_website?: string;
}

export function getDefaultInitialValue({
  defaultPitch,
  clientValue = '',
  contactValue = '',
  industry = '',
  emailList = [],
  contactList = [],
  managerList = [],
  company_website = '',
  primary_recruiter = '',
  account_manager = '',
}: GetDefaultInitialValueProps): any {
  return {
    //Client detail
    job_type: '1',
    client: clientValue || '',
    emailList,
    contactList,
    managerList,
    contact: contactValue || '',
    location: '',
    industry,
    cc: [],
    bcc: [],
    company_website,
    //Job detail
    client_job_title: '',
    no_of_opening: 1,
    experience_level: '',
    education: '',
    work_type: '',
    job_link_type: {
      value: 'https://', //default
      label: 'https://',
    },
    job_link_domain: '',
    employment_type: { value: 1, label: 'Full-Time' },
    compensation_type: { value: 2, label: 'Yearly' },
    salary_range_start: '',
    salary_range_end: '',
    skills: '',
    client_job_description: '',
    client_note: '',
    //recruitment team
    account_manager,
    primary_recruiter,
    recruiters: [],
    //Job ad campaign
    posting_title: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    career_page: true,
    show_profile: false,
    paid_job: false,
    free_job: true,
    job_description: DEFAULT_VALUES.job_ad.job_description,
    //Custom pitch
    campaign_status: { label: 'Active', value: true },
    subject: defaultPitch?.subject ?? '',
    body: defaultPitch?.content ?? '',
  };
}
