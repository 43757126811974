import { apiSlice } from 'store/api.slice';
import { GeoLocationType, WildCardSearchLocation } from './common.type';
import { ApiResponse } from 'services/type';

export const constantAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchGeoLocation: builder.query<
      GeoLocationType[],
      { search: string; column: string }
    >({
      query: (data) => ({
        url: '/geo-location/search',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Geo-Location'],
      transformResponse: (result: ApiResponse<GeoLocationType[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchWildSearchGeoLocation: builder.query<
      WildCardSearchLocation[],
      { currentName: string; search: string }
    >({
      query: (data) => ({
        url: '/geo-location/wild-card-search',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      providesTags: ['Wild-Card-Search'],
      transformResponse: (result: ApiResponse<WildCardSearchLocation[]>) => {
        return result.success ? result.data : [];
      },
    }),
  }),
});

export const {
  useFetchGeoLocationQuery,
  useLazyFetchGeoLocationQuery,
  useFetchWildSearchGeoLocationQuery,
} = constantAPI;
