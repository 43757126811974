import { useToast as useChakraToast } from '@chakra-ui/react';

export default function useToast() {
  const toast = useChakraToast({
    isClosable: true,
    duration: 3000,
    position: 'top',
  });
  return toast;
}
