import { apiSlice } from 'store/api.slice';

export const jobMutation = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFullJob: builder.mutation<any, any>({
      query: (data) => ({
        url: '/jobs/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
    }),
    createQuickJob: builder.mutation<any, any>({
      query: (data) => ({
        url: '/jobs/quick',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: 'Leads', id: 'Leads List' }] : [],
    }),
    updateFullJob: builder.mutation<any, { data: any; id: number }>({
      query: ({ data, id }) => ({
        url: `/jobs/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Job-Detail', id: 'List' },
              { type: 'Job-Detail', id },
            ]
          : [],
    }),
    updateQuickJob: builder.mutation<any, { data: any; id: number }>({
      query: ({ data, id }) => ({
        url: `/jobs/update-quick/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Job-Detail', id: 'List' },
              { type: 'Job-Detail', id },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateFullJobMutation,
  useCreateQuickJobMutation,
  useUpdateFullJobMutation,
  useUpdateQuickJobMutation,
} = jobMutation;
