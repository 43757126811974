import { useEffect, useState } from 'react';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import Button from 'Library/Button';
import {
  listClients,
  useCreateClientLocationMutation,
  useUpdateClientLocationMutation,
} from 'store/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import AtsSelect from 'components/app/AtsSelect';
import { useGetCountriesMutation } from 'store/constant.slice';
import AddressComponent from 'components/app/Leads/components/AddressComponent';
import ScrollToFieldError from 'components/app/ScrollError';
import { useGetJobsMutation } from 'store/jobs.slice';
import { useFetchCountriesQuery } from 'services/common/constants.query';

interface JobsAddressModalProps {
  isOpen: any;
  onClose: any;
  addressData?: any;
}

const JobsAddressModal = ({
  isOpen,
  onClose,
  addressData,
}: JobsAddressModalProps) => {
  const dispatch = useDispatch();
  const { client_id, clientData } = useSelector((state: any) => state.clients);
  const { jobData } = useSelector((state: any) => state.jobs);
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top',
  });

  const [reqCreateLocation, resCreateLocation] =
    useCreateClientLocationMutation();
  const [reqUpdateLocation, resUpdateLocation] =
    useUpdateClientLocationMutation();
  const { data: countriesList } = useFetchCountriesQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((country) => ({
              label: country.country,
              value: country.id,
              code: country.code,
            }))
          : [],
        ...other,
      }),
      skip: !isOpen,
    }
  );

  const formatCountry = (country: any) => {
    if (!country) return '';
    let option: any = [];
    try {
      option.push({
        label: country?.country,
        value: country?.id,
        code: country?.code,
      });
    } catch (e) {
      console.error('e', e);
    }
    return option;
  };

  const isUpdate = Object.keys(addressData).length > 0;

  const initialValues: any = {
    address: {
      location: addressData?.location || '',
      address: addressData?.address || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      country: addressData?.country
        ? formatCountry(addressData?.country) ||
          countriesList?.filter((country) => country.code === 'us')
        : '',
      zip_code: addressData?.zip_code || '',
      primary: addressData?.primary || true,
    },
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      address: Yup.object().shape({
        location: Yup.string().required('Location is required.'),
        address: Yup.string(),
        city: Yup.string().required('City is required.'),
        state: Yup.string().required('State is required.'),
        country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
        zip_code: Yup.string(),
        primary: Yup.boolean(),
      }),
    })
  );

  const tabListIndex: any = [{ id: 0, title: 'Address Information' }];

  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = (data: any) => {
    // if (true) return;
    if (client_id || clientData?.id) {
      if (isUpdate) {
        const updateLocation = reqUpdateLocation({
          id: addressData.id,
          data: {
            ...data.address,
            country: data?.address?.country?.label
              ? data.address?.country
              : data?.address?.country?.[0],
          },
        }).unwrap();
        toast.promise(updateLocation, {
          success: {
            title: 'Updated Address!',
          },
          error: {
            title: 'Update Address Error',
            description: 'Something wrong',
          },
          loading: { title: 'Updating Address...' },
        });
      } else {
        const createLocation = reqCreateLocation({
          id: client_id || clientData?.id,
          data: data.address,
        }).unwrap();
        toast.promise(createLocation, {
          success: {
            title: 'Added Address!',
          },
          error: {
            title: 'Add Address Error',
            description: 'Something wrong',
          },
          loading: { title: 'Adding Address...' },
        });
      }
      onClose();
    }
  };

  const [reqGetJob] = useGetJobsMutation();

  useEffect(() => {
    if (resCreateLocation.isSuccess || resUpdateLocation.isSuccess) {
      dispatch(listClients({ selected_client_location: [] }));

      if (jobData) {
        reqGetJob({ id: jobData.id });
      }
    }
  }, [
    client_id,
    jobData,
    dispatch,
    onClose,
    resCreateLocation.isSuccess,
    resUpdateLocation.isSuccess,
    reqGetJob,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        listClients({ selected_client: [], selected_client_location: [] })
      );
    };
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
          addressData = {};
          dispatch(
            listClients({ selected_client: [], selected_client_location: [] })
          );
        }}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {isUpdate
              ? 'Update Address Information'
              : 'Create New Address Information'}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    There was an error processing your request. Change a few
                    things up and try again.
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
              <Flex gap={2} background="#F9F9FB">
                <Box
                  width="30%"
                  py={4}
                  overflowY="scroll"
                  borderRight="1px solid"
                  borderColor="transparent"
                  px={6}
                >
                  <TabList
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="8px"
                  >
                    {tabListIndex.map((tab: any) => {
                      return (
                        <Tab
                          key={`questTab-${tab.id}`}
                          justifyContent="space-between"
                          width="100%"
                          border="0.5px solid rgba(155, 155, 155, 0.5)"
                          borderRadius="3px"
                          background="#fff"
                          p="12px 20px"
                          lineHeight="18px"
                          fontSize="14px"
                          fontWeight="600"
                          _selected={{
                            bg: '#EFE5FF',
                            border: '0.5px solid #EFE5FF',
                          }}
                          onClick={() => setTabIndex(tabListIndex[tab.id])}
                        >
                          {tab.title}
                          <Icon
                            as={ChevronLeftIcon}
                            transition="all .25s ease-in-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6}
                            ml="8px"
                            color={
                              tabIndex.id === tab.id ? 'primary.800' : '#fff'
                            }
                          />
                        </Tab>
                      );
                    })}
                  </TabList>
                </Box>

                <Box width="70%">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      errors,
                      touched,
                      isValid,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <ScrollToFieldError />
                          <TabPanels>
                            <TabPanel>
                              {/* Screening Questions */}
                              <Box
                                overflowY="scroll"
                                maxHeight="450px"
                                p="30px"
                                background="#fff"
                                borderRadius="8px"
                              >
                                <Flex gap="32px" mb="34px">
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.address && touched.address
                                    )}
                                  >
                                    <AddressComponent
                                      setFieldValue={setFieldValue}
                                      index={0}
                                      handleChange={handleChange}
                                      countryOption={countriesList}
                                      countryField={{
                                        name: `address.country`,
                                        value: values.address.country,
                                        errors: errors.country,
                                        touched: touched.country,
                                      }}
                                      locationField={{
                                        name: `address.location`,
                                        value: values.address.location,
                                        errors: errors.location,
                                        touched: touched.location,
                                      }}
                                      addressField={{
                                        name: `address.address`,
                                        value: values.address.address,
                                        // errors: errors.address,
                                        // touched: touched.address,
                                      }}
                                      cityField={{
                                        name: `address.city`,
                                        value: values.address.city,
                                        // errors: errors.city,
                                        // touched: touched.city,
                                      }}
                                      stateField={{
                                        name: `address.state`,
                                        value: values.address.state,
                                        // errors: errors.state,
                                        // touched: touched.state,
                                      }}
                                      zipField={{
                                        name: `address.zip_code`,
                                        value: values.address.zip_code,
                                        // errors: errors.zip_code,
                                        // touched: touched.zip_code,
                                      }}
                                    />
                                  </FormControl>
                                </Flex>
                              </Box>
                            </TabPanel>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              <Button
                                variant="solid"
                                onClick={() => {
                                  handleSubmit();
                                  !isValid && onOpen();
                                }}
                                disabled={isOpenAlert}
                              >
                                {isUpdate ? 'Update' : 'Save'}
                              </Button>
                            </ModalFooter>
                          </TabPanels>
                        </form>
                      );
                    }}
                  </Formik>
                </Box>
              </Flex>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JobsAddressModal;
