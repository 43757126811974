import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router';
import Sidebar from './sidebar';

export default function Layout() {
  return (
    <Flex
      gap="8px"
      mt="4px"
      p="8px"
      fontFamily="NunitoSans Regular"
      h="calc(100vh - 68px)"
      overflowY="hidden"
    >
      <Sidebar />
      <Box w="calc(100% - 300px)" h="100%" background="default.white.800">
        <Outlet />
      </Box>
    </Flex>
  );
}
