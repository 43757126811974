import { useTheme } from '@chakra-ui/react';
import { useRef } from 'react';
import ReactSelect, { Props } from 'react-select';
import { ATSTheme } from 'theme/theme';

interface SelectProps<T> extends Props<T> {
  isError?: boolean;
  selectTheme?: 'filter';
}
export default function Select<T>(props: SelectProps<T>) {
  const { colors } = useTheme<ATSTheme>();
  return (
    <ReactSelect
      {...props}
      styles={{
        ...props?.styles,
        control: (styles, { isFocused }) => ({
          ...styles,
          fontSize: '14px',
          borderWidth: '1.5px',
          borderColor: isFocused
            ? colors.primary[800]
            : props?.isError
            ? ' #E53E3E'
            : '#E7E9ED',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow: 'none',
          '&:active': {
            borderColor: colors.primary[800],
            outlineWidth: 2,
          },
          '&:hover': {
            ...styles[':hover'],
          },
          '& ::-webkit-scrollbar': {
            display: 'none',
          },
          ...props?.styles?.control,
        }),
        option: (styles, { isSelected, isFocused, isDisabled }) => ({
          ...styles,
          fontSize: '14px',
          background: isSelected
            ? colors.primary[800]
            : isFocused
            ? colors.primary[100]
            : styles.color,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? styles.color
                : colors.primary[200]
              : undefined,
          },
          cursor: isDisabled ? 'not-allowed' : 'default',
          zIndex: 20,
          ...props?.styles?.option,
        }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          color: '#676767',
          ...props?.styles?.dropdownIndicator,
        }),
        indicatorSeparator: () => ({
          display: 'none',
          ...props?.styles?.indicatorSeparator,
        }),
        placeholder: (styles: any) => ({
          ...styles,
          fontSize: '14px',
          color: '#9B9B9B',
          ...props?.styles?.placeholder,
        }),
        singleValue: (styles: any) =>
          props?.selectTheme === 'filter'
            ? {
                ...styles,
                color: '#6930CA',
                fontSize: '14px',
                fontWeight: '600',
              }
            : { ...styles },
        multiValue: (styles: any) =>
          props?.selectTheme === 'filter'
            ? {
                ...styles,
                background: '#F7FAFC',
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                '&& div:first-of-type': {
                  color: '#6930CA',
                  fontWeight: 600,
                },
              }
            : { ...styles },
      }}
    />
  );
}
