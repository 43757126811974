import { Accordion, Box, Flex, useDisclosure } from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import Button from 'Library/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  listCandidateInterview,
  useCancelInterviewMutation,
  useListCandidateInterviewMutation,
} from 'store/candidateinterview.slice';
import {
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { useGetJobsMutation, useOpenJobsMutation } from 'store/jobs.slice';
import { candidateDataInt, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import { boolean } from 'yup';
import CandidateJobList from '../../components/CandidateJobList';
import InterviewAccordion from './InterviewAccordion';
import CandidatesInterviewModal from './modal';
import CancelModal from './modal/Cancel';
interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
}

interface props {
  candidateData: candidateDataInt;
}

interface jobList {
  value: number;
  serial_number: string;
  label: string;
  client_name: string;
  client_id: number;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CandidatesInterviews = ({ candidateData }: props) => {
  const params = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const [reqList, resList] = useListCandidateInterviewMutation();
  const [reqOpen] = useOpenJobsMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqCancel, resCancel] = useCancelInterviewMutation();
  const { jobData, openJobs } = useSelector((state: any) => state.jobs);
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [subject, setSubject] = useState('');
  const [jobList, setJobList] = useState([]);
  const [actionType, steActionType] = useState('');
  const [assocId, setAssocId] = useState(0);
  const [jobId, setJobId] = useState(null);
  const callBackAction = () => {
    reqGetCandidate({ id: candidateData?.id });
  };
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const [jobListDrowpDown, setJobListDropDown] = useState<jobList[]>([]);
  const [defaultValue, setDefaultValue] = useState<jobList>({
    label: 'All',
    value: 0,
    serial_number: '',
    client_id: 0,
    client_name: '',
  });
  const [groupByJobs, setGroupByJobs] = useState([]);
  const [interviewList, setInterviewList] = useState([]);
  const [currentSelectedJob, setCurrentSelectedJob] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState<any>([]);
  const [isEditInterview, setIsEditInterview] = useState<boolean>(false);
  const [interviewData, setInterviewData] = useState<any | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchCandidates = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    await reqCandidates({
      data: candidatePgBtn,
      signal: abortControllerRef.current.signal,
    });
  };

  useEffect(() => {
    if (resCancel.isSuccess) {
      fetchCandidates();
    }
  }, [resCancel.isSuccess]);

  const populateData = async (data: any) => {
    let jobs: jobList[] = [
      {
        label: 'All',
        value: 0,
        serial_number: '',
        client_id: 0,
        client_name: '',
      },
    ];

    const job_id = params?.jobId || query?.get('jobId');
    if (job_id && jobData) {
      jobs.push({
        value: Number(jobData.id) || 0,
        label:
          [jobData?.title, jobData?.client?.name].filter(boolean).join(' - ') ||
          '',
        serial_number: jobData?.serial_number || '',
        client_name: jobData?.client?.name || '',
        client_id: Number(jobData?.client_id) || 0,
      });
      if (job_id == jobData.id) {
        setCurrentSelectedJob(Number(jobData.id));
        setDefaultValue({
          value: Number(jobData.id) || 0,
          label:
            [jobData?.title, jobData?.client?.name]
              .filter(boolean)
              .join(' - ') || '',
          serial_number: jobData?.serial_number || '',
          client_name: jobData?.client?.name || '',
          client_id: Number(jobData?.client_id) || 0,
        });
      }
    }

    const groupings = data.length > 0 ? data : candidateData?.jobs;

    const groupedByJobId = groupings?.reduce((acc: any, currentItem: any) => {
      // If the group (job_id) doesn't exist, create an empty array for it

      if (!acc[currentItem.job_id]) {
        acc[currentItem.job_id] = [];
        if (currentItem?.job) {
          const exists = jobs.some(
            (item: any) => item.value === Number(currentItem.job_id)
          );

          if (!exists) {
            jobs.push({
              value: Number(currentItem.job_id) || 0,
              label:
                [currentItem?.job?.title, currentItem?.job?.client?.name]
                  .filter(boolean)
                  .join(' - ') || '',
              serial_number: currentItem?.job?.serial_number || '',
              client_name: currentItem?.job?.client?.name || '',
              client_id: Number(currentItem?.job?.client_id) || 0,
            });
          }
        }
      }

      // Push the current item to its group
      acc[currentItem.job_id].push(currentItem);

      return acc;
    }, {});

    if (candidateData?.jobs?.length > 0 && !params?.jobId) {
      jobs = [
        {
          label: 'All',
          value: 0,
          serial_number: '',
          client_id: 0,
          client_name: '',
        },
      ];
      candidateData?.jobs?.map((item: any) => {
        if (item?.job) {
          jobs.push({
            label:
              [item?.job?.title, item?.job?.client?.name]
                .filter(boolean)
                .join(' - ') || '',
            value: Number(item?.job?.id) || 0,
            serial_number: item?.job?.serial_number || '',
            client_name: item?.job?.client?.name || '',
            client_id: Number(item?.job?.client_id) || 0,
          });

          if (job_id == item.job_id) {
            setCurrentSelectedJob(item.job_id);
            setDefaultValue({
              value: Number(item.job_id) || 0,
              label:
                [item?.job?.title, item?.job?.client?.name]
                  .filter(boolean)
                  .join(' - ') || '',
              serial_number: item?.job?.serial_number || '',
              client_name: item?.job?.client?.name || '',
              client_id: Number(item?.job?.client_id) || 0,
            });
          }
        }
      });
    }

    setJobListDropDown(jobs);
    setGroupByJobs(groupedByJobId);
    if (groupedByJobId[currentSelectedJob] && data.length > 0) {
      setInterviewList(groupedByJobId[currentSelectedJob]);
    }
  };

  useEffect(() => {
    if (resList.isSuccess) {
      const data: any = resList?.data?.data?.data;
      populateData(data);
    }
  }, [resList.isSuccess]);

  const {
    candidateInterviews,
    candidateInterviewPage,
    candidateInterviewPgBtn,
  } = useSelector((state: any) => state.candidatesInterview);

  useEffect(() => {
    if (
      params?.jobId !== undefined &&
      params?.jobId !== 'undefined' &&
      params?.jobId !== null
    ) {
      reqGetJob({ id: params?.jobId });
    }
  }, []);

  const fetchList = () => {
    const id = params?.candidatesId || params?.candidateId;
    let data = {};
    if (params?.jobId) {
      data = {
        ...data,
        job_id: params?.jobId || null,
      };
    }
    reqList({ id });
  };

  const closeInterview = () => {
    setTimeout(() => {
      fetchList();
    }, 2500);

    onClose();
  };

  useEffect(() => {
    const id = params?.candidatesId || params?.candidateId;
    fetchList();
    reqOpen({ query: '', candidate_id: Number(id) });
  }, [params.candidatesId, params?.candidateId]);

  useEffect(() => {
    if (
      params?.jobId !== undefined &&
      params?.jobId !== 'undefined' &&
      params?.jobId !== null &&
      resGetJob.isSuccess
    ) {
      const contactOption = [
        {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(
            ' '
          ),
          value: jobData?.lead?.id,
        },
      ];

      setContactOptionValue(contactOption);
      const client_name = jobData.client ? jobData?.client?.name : '';
      const subject =
        'Video Interview' +
        ' | ' +
        jobData?.title +
        ' | ' +
        candidateData?.first_name +
        ' ' +
        candidateData?.last_name +
        '/' +
        client_name;

      setSubject(subject);
    }
  }, [resGetJob.isSuccess]);

  const tableHeader: { label: any }[] = [
    { label: 'Interview Name/Type' },
    { label: 'Job' },
    { label: 'Status' },
    { label: 'From' },
    { label: 'To' },
    { label: 'Panels' },
    { label: 'Meeting Link' },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCancelModal,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  const scheduleInterview = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    setIsEditInterview(false);
    steActionType('interview');
    await Promise.all(
      candidateData?.jobs?.map(async (item: any) => {
        if (params?.jobId || query?.get('jobId')) {
          if (
            ((item.candidate_status_id === 2 ||
              item.candidate_status_id === 1 ||
              item.candidate_status_id === 3 ||
              item.candidate_status_id === 8) &&
              item?.job?.id == params?.jobId) ||
            query?.get('jobId') == item?.job?.id
          ) {
            if (item?.job) {
              jobs.push(item);
              job_id = item?.job?.id;
              assoc = item;
            }
          }
        } else {
          if (
            item.candidate_status_id === 2 ||
            item.candidate_status_id === 1 ||
            item.candidate_status_id === 3 ||
            item.candidate_status_id === 8
          ) {
            if (item?.job) {
              jobs.push(item);
              job_id = item?.job?.id;
              assoc = item;
            }
          }
        }
      })
    );

    setJobList(jobs);
    if (jobs.length === 1) {
      onJobSelect({
        id: Number(job_id),
        type: 'interview',
        assoc_id: assoc?.id,
        reject: assoc?.sub?.target,
      });
    } else {
      onOpenJobList();
    }
  };
  const onJobSelect = (data: jobselectInt) => {
    setAssocId(data.assoc_id);
    if (data.type === 'interview') {
      reqGetJob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        const job = res?.data?.data;
        if (data.type === 'interview') {
          const client_name = job?.data?.client ? job?.data?.client?.name : '';
          const subject =
            'Video Interview' +
            ' | ' +
            job?.data?.title +
            ' | ' +
            candidateData?.first_name +
            ' ' +
            candidateData?.last_name +
            '/' +
            client_name;

          setSubject(subject);
          let contactOption: any = [];
          contactOption = [
            {
              label: [
                job?.data?.lead?.first_name,
                job?.data?.lead?.last_name,
              ].join(' '),
              value: job?.data?.lead?.primary_email,
            },
          ];
          setContactOptionValue(contactOption);
          onOpen();
        }
      });
    }
  };
  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const jobChangeList = (e: any) => {
    setDefaultValue(e);
    setCurrentSelectedJob(e.value);
  };

  const memoizecandidateInterviews = useMemo(() => {
    return candidateInterviews;
  }, [candidateInterviews]);

  useEffect(() => {
    if (!resList.isSuccess) {
      if (currentSelectedJob != 0) {
        setInterviewList(groupByJobs[currentSelectedJob]);
      } else {
        setInterviewList(memoizecandidateInterviews);
      }
    }
  }, [resList.isSuccess, memoizecandidateInterviews, groupByJobs]);

  useEffect(() => {
    if (currentSelectedJob != 0) {
      setInterviewList(groupByJobs[currentSelectedJob]);
    } else {
      setInterviewList(memoizecandidateInterviews);
    }
  }, [currentSelectedJob, memoizecandidateInterviews, groupByJobs]);

  const [cancelData, setCancelData] = useState({});
  const cancelhandleSubmit = async () => {
    const interview: any = cancelData;
    const id = interview?.id;

    await reqCancel({ id }).then(async () => {});
    // await fetchList();

    //virtually remove the canceled interview since the right results has delays
    const newList = candidateInterviews.filter((item: any) => item.id != id);
    await populateData(newList);

    const currentInterviewList = interviewList.filter(
      (item: any) => item.id != id
    );

    setInterviewList(currentInterviewList);
    dispatch(listCandidateInterview({ candidateInterviews: newList }));
    setExpandedIndex(currentInterviewList);
  };

  useEffect(() => {
    if (resCancel.isSuccess && resCandidates.isSuccess) {
      onCloseCancelModal();
    }
  }, [resCancel.isSuccess, resCandidates.isSuccess]);

  const editInterview = async (interview: any) => {
    setJobId(interview.job_id);
    setSubject(interview.subject);
    setIsEditInterview(true);
    setInterviewData(interview);
    let contactOption: any = [];
    await Promise.all(
      interview.panels.map((panel: any) => {
        contactOption.push({
          label: panel?.email,
          value: panel?.email,
        });
      })
    );

    setContactOptionValue(contactOption);
    onOpen();
  };

  return (
    <Box justifyContent="space-between">
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Box width="50%">
          <AtsSelect
            options={jobListDrowpDown}
            placeholder="Select a Job"
            defaultValue={defaultValue}
            value={defaultValue}
            onChange={jobChangeList}
            styles={{
              container: (styles: any) => ({
                ...styles,
                borderColor: '#E7E9ED',
              }),
              singleValue: (styles: any) => ({
                ...styles,
                fontSize: '14px',
                fontWeight: 700,
                color: '#6B46C1',
              }),
            }}
          />
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={scheduleInterview}>
          Schedule Interview
        </Button>
      </Flex>
      <Accordion
        allowMultiple
        index={expandedIndex}
        onChange={setExpandedIndex}
      >
        {interviewList?.length > 0 &&
          interviewList?.map((interview: any, key: number) => {
            return interview?.job && interview?.panels ? (
              <InterviewAccordion
                interview={interview}
                key={`interview-accordion-${key}`}
                cancelInterView={(interview) => {
                  onOpenCancelModal();
                  setCancelData(interview);
                }}
                editInterview={(interview) => editInterview(interview)}
              />
            ) : (
              <></>
            );
          })}
      </Accordion>

      {isOpenJobList && (
        <CandidateJobList
          jobs={jobList}
          isOpen={isOpenJobList}
          onClose={onCloseJobList}
          onSelect={(e: any) => onJobSelect(e)}
          type={actionType}
        />
      )}
      {isOpen && (
        <CandidatesInterviewModal
          associate={assocId}
          isJobView={true}
          jobsId={jobId}
          isOpen={isOpen}
          onClose={closeInterview}
          subject={subject}
          contactOptionValue={contactOptionValue}
          isEdit={isEditInterview}
          interviewData={interviewData}
          callback={() => {
            callBackAction();
          }}
        />
      )}

      {isOpenCancelModal && (
        <CancelModal
          isOpen={isOpenCancelModal}
          onClose={onCloseCancelModal}
          handleSubmit={cancelhandleSubmit}
          label="cancel an interview"
          loading={resCancel.isLoading || resCandidates.isLoading}
        />
      )}
    </Box>
  );
};

export default CandidatesInterviews;
