import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useFetchCandidateByIdQuery } from 'services/candidates/candidate.query';
import { CandidateByIdResInterface } from 'services/candidates/candidate.type';

function formatCurrentDisplayJob(
  job: CandidateByIdResInterface['data']['jobs'][0]
) {
  if (job) {
    let displayJob = { ...job } as typeof job & { location: string };
    if (job?.job?.jobads?.length) {
      const jobAds = job?.job?.jobads?.at(0);
      const location = [jobAds?.city, jobAds?.state]
        ?.filter(Boolean)
        ?.join(' ');
      displayJob = {
        ...displayJob,
        distance_from_job: Math.ceil(job.distance_from_job),
        location,
      };
    }
    return displayJob;
  }
  return null;
}
function getInterviewStatus(data: { status: number; counter: number } | null) {
  if (data) {
    const { status, counter } = data;
    if (status === 1) {
      return `Interview #${counter} - Scheduled`;
    }
    if (status === 2) {
      return `Interview #${counter} - Completed`;
    }
  }
  return '';
}
function formatCandidateData(data: CandidateByIdResInterface, jobId: number) {
  if (data) {
    const { data: candidateDetail, ...otherData } = data;
    return {
      ...candidateDetail,
      ...otherData,
      currentDisplayJob: formatCurrentDisplayJob(
        jobId
          ? candidateDetail?.jobs?.find?.((job) => job?.job_id === jobId)
          : null
      ),
      rejectedJobs:
        candidateDetail?.jobs?.filter?.(
          (job) => job.candidate_status_id === 6
        ) ?? [],
      unrejectedJobs:
        candidateDetail?.jobs?.filter?.(
          (job) => job.candidate_status_id !== 6
        ) ?? [],
      interviewStatus: getInterviewStatus(
        otherData?.interviews
          ?.filter(Boolean)
          ?.find?.((interview) => interview?.job_id === jobId)
      ),
    };
  }
  return null;
}
export default function useGetCandidate() {
  const params = useParams();
  const jobId = parseInt(params?.jobId);
  const candidateId = parseInt(params?.candidateId);
  const {
    data: candidateDetails,
    isFetching: isLoadingCandidate,
    ...other
  } = useFetchCandidateByIdQuery(
    {
      id: candidateId,
    },
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: formatCandidateData(data, jobId),
      }),
    }
  );

  return { candidateDetails, isLoadingCandidate, ...other };
}
